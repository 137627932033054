 
.border-top{
    /* border-top: 1px solid black; */
}
.shortSummary .border-left {
    border-left: 1px solid black;
}
.card-container{
    /* margin-left: 10px; */
    /* padding-right: 30px;  */
    padding: 0px 20px;
    margin-bottom: 10px;
    .clickable-report-card {
        
        background-color: white;
        padding: 0px;
        /* min-height: 9rem; */
        border-radius: 10px;
        .title{
            text-align: center !important;
            h6{ font-size: 1em; text-align: center !important;}
        }
        .title.users{ 
            margin-top: 3%;
        }
        .title.assessed{
            background-color: #5456fb;
            color: white;
        }
        .title.safe{
            background-color: #008000;
            color: white;
        }
        .title.risk{
            background-color: #FF8C00;
            color: white;
        }
        .data {
            padding: unset;
            h4{
                text-align: center;
                font-size: 2em;
                font-weight: 500;
            }
        }
    }
    .clickable-report-card.card1{ 
        background-color: #f2f3f300; /*  border: 1px solid #fff; */ color: #fff; 
        box-shadow: 0 7px 10px rgba(0, 0, 0, 0.16), 0 6px 6px rgba(0, 0, 0, 0.1);
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        .title{text-align: right !important;}
        .react-date-picker__clear-button {
            display: none !important;
        }
        .border-top{ 
            /* border-top-color: #fff; */
        }
    }
} 
.react-date-picker__button__input__input{min-width: 17px !important;}
.react-date-picker__button__input__year{width: unset !important;}
.office-safety-summary .slider{
    background-color: #26a65b !important; 
}
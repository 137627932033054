    .file-upload {
        .images {
            margin: 10px 0px;
            .image-wrapper {
                position: relative;
                display: inline-block;
                &:hover {
                    .delete-image {
                        visibility: visible;
                    }
                    background: lightgray;
                }
                .delete-image {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    color: #6f6f6f;
                    background: lightgray;
                    /* z-index: 1; */
                    outline: none;
                    border: none;
                    font-size: 16px;
                    width: 30px;
                    height: 30px;
                    border-bottom-left-radius: 15px;
                    visibility: hidden;
                    &:hover {
                        cursor: pointer;
                    }
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
        .imageDangerAlert{
            .danger-alert.alert-field{
                p {
                    line-height: normal !important;
                }
            }
        }
    }

    @mixin visibleDeleteButton {
        .file-upload {
            .images {
                .image-wrapper {
                    .delete-image {
                        visibility: visible;
                    }
                }
            }
        }
    }

    @media only screen and (min-width:320px) and (max-width:1024px) {
        @include visibleDeleteButton();
    }

    @media only screen and (width:1366px) {
        @include visibleDeleteButton();
    }
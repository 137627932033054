    @import '../../constants.scss';
    @import '../JSAForm/jsaForm.scss';
    .incident-report-form {
        margin-bottom: 100px;
        .form-header {
            text-align: center;
            .forward-button {
                display: inline-block;
                vertical-align: middle;
                position: absolute;
                right: 11%;
            }
            .step-label {
                display: inline-block;
                line-height: 25px;
                color: #BFBFBF;
                padding: 0px 60px;
                font-size: 17px;
            }
            .backward-button {
                display: inline-block;
                vertical-align: middle;
                position: absolute;
                left: 11%;
            }
        }
        .severity-form {
            .Select-menu-outer {
                min-height: 200px;
                overflow-y: auto;
            }
        }
        Label{
            color:yellow;
        }
        .navigateNextButton{
            background-color: green;
            padding: 4px;
            border-radius: 10px;
            cursor: pointer;
        }
        .navigatePreviousButton{
            background-color: gray;
            padding: 4px;
            border-radius: 10px;
            cursor: pointer;
        }
    }

    .cross-button {
        &:hover {
            cursor: pointer;
        }
    }

    .incident-report-form {
        .form-middle {
            border-left: 1px solid #c1c1c0;
            border-right: 1px solid #c1c1c0;
            margin: 0 2px;
            fieldset {
                border-left: none;
                border-right: none;
                border-radius: 0;
                border-bottom: 0;
            }
        }
    }

    .incident-investigative-form {
        padding: 20px 0;
        fieldset {
            border-radius: 40px;
        }
        .radio-buttons {
            position: relative;
            left: 5px;
            display: inline-block;
            .radio-input:not(:checked)+label,
            .radio-input:checked+label {
                bottom: 0px;
                top: -22px;
                left: 6px;
            }
        }
        legend {
            margin-bottom: 20px;
            margin-left: 20px;
            font-size: 18px;
            line-height: inherit;
            color: #333;
            border-bottom: none;
            width: auto;
        }
        .col-xs-12 {
            padding: 0 !important;
        }
        .form-top-fieldset {
            border-bottom-left-radius: 0;
            padding: 20px;
            border-bottom-right-radius: 0;
            border-bottom: none;
        }
        .form-bottom {
            padding: 0;
            border-right: 1px solid #c1c1c0;
            margin-right: 2px;
            border-bottom-right-radius: 46px;
            border-left: 1px solid #c1c1c0;
            margin-left: 2px;
            border-bottom-left-radius: 46px;
        }
        .form-bottom-fieldset {
            padding: 20px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-left: none;
            border-right: none;
        }
    }

    @media (min-width: 769px) {
        .safeconnect-form {
            title{
                color:yellow;
            }            
            .incident-report-form {
                .checkbox-row {
                    margin: 0 0px 20px 0;
                }
            }
        }
        .incident-investigative-form {
            padding: 20px 30px;
            width: 1078px;
            .checkbox-row {
                border: 1px solid #c1c1c0;
                padding: 0 !important;
                margin: 0 0px 20px 0;
                text-align: center;
                border-radius: 30px;
                .col-sm-4 {
                    padding: 15px 0 10px 0px;
                }
                .checkbox-center {
                    border-right: 1px solid #c1c1c0;
                    border-left: 1px solid #c1c1c0;
                }
            }
            .incident-radio-buttons {
                .checkbox-label {
                    top: 0px;
                }
            }
            .injury-radio-buttons {
                .checkbox-label-one {
                    padding-top: 15px;
                }
                .work-related {
                    line-height: 62px !important;
                }
                .checkbox-center {
                    padding: 22px;
                    height: 72px;
                }
                .checkbox-right {
                    height: 72px;
                    padding: 22px;
                }
            }
            .date-input,
            .time-input {
                padding: 0 15px !important;
            }
            .input-label {
                text-align: left;
            }
            .direct-cause {
                text-align: right;
            }
        }
    }

    .injury-illness {
        .checkbox-row {
            text-align: center;
            .employee {
                text-align: right;
            }
            .contractor {
                text-align: left;
            }
        }
    }

    .incident-investigative-report {
        margin: auto;
        padding: 20px;
        .page-title {
            margin-bottom: 20px;
        }
        .follow-up {
            padding-top: 5.5px;
            display: inline;
            position: absolute;
        }
        .show-grid {
            padding-bottom: 20px;
        }
        .location-browser {
            border: 1px solid #cccccc;
            line-height: 30px;
            border-radius: 17px;
            padding: 0px 0px;
            margin-left: 15px;
            width: 45.5%;
        }
        .export-button-container {
            width: 50%;
            float: left;
            text-align: right;
        }
        .associated-forms {
            line-height: 3;
            .button {
                line-height: 0;
            }
        }
    }

    @include detailsPageStepRows();
    .incident-report-form,
    .general-information,
    .injury-illness,
    .incident-details,
    .security-form,
    .severity-form,
    .incident-investigation-summary,
    .emdc-form {
        textarea {
            resize: none;
        }
        a {
            &:hover {
                cursor: pointer;
            }
        }
        .form-control {
            border-radius: 17px;
        }
        .steps {
            text-align: center;
            font-size: 20px;
        }
        font-size: 14px;
        color:white;
        .show-grid {
            .col-sm-1 {
                line-height: 50px;
            }
            padding-bottom: 20px;
            #offShore,
            #onShore {
                margin: 10px 0 0;
            }
            .work-related {
                line-height: 20px;
                text-align: left;
                label {
                    margin: 5px;
                    padding: 0px 10px;
                    .radio-input {
                        margin-right: 10px;
                    }
                }
            }
            .date-input,
            .time-input {
                padding: 0px;
            }
        }
        @include tableInput();
        .table-buttons {
            border: unset;
            .add-row {
                .fa-plus {
                    color: white;
                    font-size: 15px;
                }
            }
        }
        .checkbox-row {
            padding: 10px;
        }
        .modal-button {
            border: none;
            background-color: white;
            color: #88a4ce;
            text-decoration: underline;
        }
        .fa-chevron-circle-right,
        .fa-chevron-circle-left {
            color: $submitButtonColor;
            font-size: 24px;
            &:hover {
                cursor: pointer;
            }
        }
        .form-button {
            .fa-ban {
                font-size: 18px;
                color: #5479af
            }
            .fa-plus {
                color: white;
                font-size: 18px;
            }
        }
    }

    .incident-investigation-summary {
        padding: 20px;
        .action-items {
            .col-sm-1 {
                width: 3%;
                line-height: 35px;
            }
            .trash {
                line-height: 31px;
                padding: 2px;
                .fa-trash {
                    font-size: 20px;
                    color: #c2c2c2;
                }
            }
        }
    }

    .modal-content{
        background-color:#003152;
    }

    .modal-body {
        .btn-default {
            width: 188px;
        }
    }

    @media screen and (max-width: 768px) {
        .injury-illness {
            .checkbox-row {
                text-align: left;
                .employee {
                    text-align: left;
                }
                .contractor {
                    text-align: left;
                }
            }
        }
        .incident-investigative-report {
            .associated-forms {
                line-height: 1.5;
            }
        }
        @include detailsPageStepRowsSmallDevices();
        .incident-report-form,
        .general-information,
        .injury-illness,
        .incident-details,
        .security-form,
        .severity-form,
        .incident-investigation-summary,
        .emdc-form {
            .show-grid {
                padding-bottom: 5px;
            }
            .add-signature {
                padding: 29px 0px;
            }
        }
        .modal-body {
            .btn-default {
                width: 50%;
            }
        }
        .m-signature-pad--footer {
            padding-left: 0px;
            width: 50%;
            .btn-default {
                width: 100%;
            }
        }
        .m-signature-pad--body {
            canvas {
                width: 100%;
                height: 175px;
                border: 1px solid
            }
        }
        .show-grid {
            .col-sm-1 {
                display: inline-block;
                margin-left: -20px;
                top: 32px;
            }
        }
        .checkbox-row {
            text-align: left;
            .checkbox-field {
                margin-bottom: -5px;
                float: right;
                right: 45%;
            }
        }
        .heading {
            display: none;
        }
        .incident-investigation-summary {
            padding: unset;
            .action-items {
                width: 100%;
                padding-bottom: 30px;
                position: relative;
                margin: unset;
                .col-sm-1 {
                    width: 3%;
                    line-height: 35px;
                    margin: 0 -5%;
                }
                .action-item {
                    border-bottom-left-radius: unset;
                    border-bottom-right-radius: unset;
                }
                .party-responsible {
                    border-bottom: none;
                    border-top: none;
                    border-radius: unset;
                }
                .target-date {
                    border-top-left-radius: unset;
                    border-top-right-radius: unset;
                }
                .target-date-trash {
                    border-radius: unset;
                }
                .trash {
                    cursor: pointer;
                    text-align: center;
                    padding: 2px 15px;
                    position: absolute;
                    top: 145px;
                    right: 5%;
                    width: 100%;
                    line-height: 31px;
                    .fa-trash {
                        font-size: 20px;
                        color: white;
                        background-color: #c2c2c2;
                        line-height: 29px;
                        border-bottom-left-radius: 17px;
                        border-bottom-right-radius: 17px;
                        width: 100%;
                    }
                }
            }
            .project-oims {
                margin: unset;
                .row {
                    margin: unset;
                }
                .oims-system {
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                    border-bottom: unset;
                }
                .date {
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                }
            }
            .root-actions {
                .row {
                    margin: unset;
                    padding-bottom: 30px;
                }
            }
        }
        .general-information {
            .date-time-column {
                .input-label {
                    padding-left: 0px;
                }
            }
            .checkbox-field {
                .input-label {
                    float: left;
                }
            }
        }
    }
    @import '../../constants.scss';
    @mixin coordsStyle {
        border: 1px solid #cccccc;
        line-height: 34px;
        border-radius: 17px;
        padding: 0px 10px;
    }

    @mixin cardWrapper {
        .summary-reports {
            .container {
                padding: 10px;
                .oi-report-card,
                .office-safety-report-card {
                    margin: 2.5px auto;
                }
            }
        }
    }

    @mixin tableInput {
        .table-buttons {
            text-align: center;
            margin-top: 15px;
            width: 100%;
            border-bottom: 1px solid #4b77be;
            padding-bottom: 20px;
            .add-row {
                width: 66%;
                background-color: #4b77be;
                border: none;
                border-radius: 17px;
                color: white;
                height: 34px;
            }
        }
    }

    @mixin uploadImage {
        .upload-image {
            line-height: 30px;
            padding: 6.5px 16px;
            background: white;
            color: $submitButtonColor;
            border-radius: 17px;
            border: 1px solid $submitButtonColor;
            &:hover {
                cursor: pointer;
            }
        }
    }

    .clickable-report-card {
        cursor: pointer;
        &:hover {
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16), 0 6px 6px rgba(0, 0, 0, 0.10);
            transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
        }
    }

    .add-concern-modal {
        @include tableInput();
        .danger-alert {
            margin-top: 0px !important;
            bottom: 15px !important;
        }
        .modal-body {
            padding: 20px;
        }
        .input-label {
            text-align: right;
        }
        .col-sm-7,
        .col-sm-12 {
            padding-bottom: 15px;
        }
        .fa-trash {
            color: grey;
            &:hover {
                cursor: pointer;
            }
        }
        .input-row {
            width: 50%;
            margin-left: 35%;
        }
        .add-row-button {
            background-color: #88A4CE;
            border: none;
            border-radius: 17px;
            color: white;
            height: 34px;
        }
        .form-control {
            width: 89%;
            display: inline-block;
            margin: 0px 15px 0 0;
            border-radius: 17px;
        }
        .input-row {
            width: 50%;
            margin-left: 35%;
        }
    }

    .office-safety-form,
    .office-safety-report {
        .action-label {
            color: #88A4CE;
            padding-bottom: 5px;
        }        
    }

    .filter {
        .month-select {
            padding: 0px;
            .Select-control {
                border-bottom-right-radius: 0px;
                border-top-right-radius: 0px;
                text-align: center;
            }
            .Select-option {
                text-align: center;
            }
        }
        .year-select {
            padding: 0px;
            .Select-control {
                border-radius: 0px;
                text-align: center;
            }
            .Select-option {
                text-align: center;
            }
        }
        .done {
            padding: 0px;
            .button {
                margin: 0px !important;
                height: 36px;
                border-bottom-left-radius: 0px;
                border-top-left-radius: 0px;
                border-bottom-right-radius: 17px;
                border-top-right-radius: 17px;
                font-size: 14px;
                border: 1px solid $submitButtonColor;
                color: $submitButtonColor;
            }
        }
        .Select-menu-outer {
            z-index: 100;
        }
        .Select-option {
            text-align: center;
        }
    }

    @mixin filterSmallDevices {
        .filter {
            .month-select {
                padding: 0px;
                .Select-control {
                    border-bottom-left-radius: 0px;
                    border-top-right-radius: 17px !important;
                }
            }
            .year-select {
                padding: 0px;
                .Select-control {
                    border-radius: 0px;
                }
            }
            .done {
                padding: 0px;
                .button {
                    border-bottom-left-radius: 17px !important;
                    border-top-right-radius: 0px !important;
                }
            }
            .Select-menu-outer {
                z-index: 100;
            }
        }
    }

    @mixin listPageHeadingAdjustment {
        .list-page {
            .reports-table {
               color:white;
                .heading {
                    .col-sm-1 {
                        padding: unset;
                    }
                }
            }
        }
    }

    .oi-report-list {
        @include listPageHeadingAdjustment();
    }

    @media (min-width:769px) {
        .list-page-chart {
            display: flex;
        }
    }

    .oi-report {
        padding: 20px;
        .page-title {
            margin-bottom: 20px;
        }
    }

    .confirmation-modal {
        .success-alert {
            height: 20px;
            line-height: 1;
            position: relative;
            bottom: 7px;
        }
        .danger-alert {
            height: 30px;
            margin-top: -15px;
            padding-bottom: 10px;
            position: relative;
            bottom: 0;
        }
    }

    .oi-form,
    .oi-report {
        margin: auto;
        background-color: #003152;
        Label{
            color:yellow;
        }
        Legend{
            color:yellow;
        }
        .show-grid {
            padding-bottom: 20px;
            .checkbox-input {
                padding-top: 5.5px;
            }
        }
        .non-resizable {
            resize: none;
        }
        .success-alert {
            height: 20px;
        }
        .danger-alert {
            height: 20px;
            line-height: 0;
            margin-bottom: 5px;
        }
        .location-browser {
            border: 1px solid #cccccc;
            line-height: 30px;
            border-radius: 17px;
            padding: 0px 0px;
            margin-left: 15px;
            width: 45.5%;
        }
    }

    @mixin legendCommon {
        margin: 5px auto;
        width: 50px;
        height: 20px;
        display: inline-block;
    }

    @mixin legendSpan {
        span {
            position: relative;
            bottom: 10px;
            left: 10px;
        }
    }

    .chart {
        width: 100%;
        .legends {
            height: 200px;
            overflow: auto;
            text-align: left;
            margin: 20px 0;
            padding: 0px 5px;
            .legend-field {
                margin: 10px 0px;
            }
            .safety-legend-box {
                @include legendCommon();
                background-color: #FFBECE;
            }
            .health-legend-box {
                @include legendCommon();
                background-color: #B0D9FF;
            }
            .environment-legend-box {
                @include legendCommon();
                background-color: #FFF6BE;
            }
            .security-legend-box {
                @include legendCommon();
                background-color: #A9DEDC;
            }
            .other-legend-box {
                @include legendCommon();
                background-color: #E7BFFF;
            }
            span {
                position: relative;
                bottom: 10px;
                left: 10px;
                color:#88a4ce;
            }
        }
    }

    .line-chart-legends {
        .actual-legend {
            display: inline-block;
            width: 50%;
            .actual-legend-box {
                @include legendCommon();
                background-color: #FFBECE;
            }
            @include legendSpan();
        }
        .expected-legend {
            display: inline-block;
            width: 50%;
            .expected-legend-box {
                @include legendCommon();
                background-color: #B0D9FF;
            }
            @include legendSpan();
        }
    }

    .oi-report-card,
    .office-safety-report-card {
        height: 67px;
        width: 200px;
        padding: 0px;
        background-color: white;
        margin: 20px auto;
        .col-sm-4 {
            text-align: center;
        }
        img {
            float: left;
        }
        p {
            font-size: 20px;
            margin: auto;
            text-align: center;
        }
        h4 {
            margin: 5px 0px 0px 0px;
            font-size: 14px;
            width: 120px;
            display: inline-block;
            text-align: center;
        }
    }

    .oi-report-summary,
    .incident-report-summary {
        padding: 20px 0;
        .container {
            padding: 0px;
        }
        .summary-reports {
            .bar-chart,
            .line-chart {
                font-size: 10px;
                .recharts-wrapper {
                    width: 100%;
                }
                svg {
                    width: 100%;
                    margin-left: -50px;
                }
            }
        }
        .row {
            margin: auto;
            .col-sm-12 {
                text-align: center;
            }
        }
    }

    .oi-form-list {
        padding: 20px;
        min-height: 84vh;
    }

    @media screen and (max-width: 666px) {
        .chart {
            .legends {
                height: 135px;
                text-align: left;
                padding: 0px 5px;
            }
        }
        @include cardWrapper();
        .add-concern-modal {
            padding: 0px 20px;
            .form-control {
                margin-right: 5px;
            }
            .input-row {
                width: 90%;
                margin-left: 0px;
                margin-right: 5px;
            }
        } // .oi-report-summary,
        // .incident-report-summary {
        //     .summary-reports {
        //         padding: 0px;
        //         .safety-legend,
        //         .health-legend,
        //         .environment-legend,
        //         .security-legend {
        //             width: 50%;
        //         }
        //         .other-legend {
        //             text-align: center;
        //         }
        //     }
        // }
        .oi-form,
        .office-safety-form {
            .show-grid {
                padding-bottom: 5px;
            }
          .placeholder-color{
            color:black;
          }
        }
        .checkbox-input {
            display: inline-block;
        }
        .oi-report {
            .latitude,
            .longitude {
                @include coordsStyle();
            }
        }
    }

    @media screen and (min-width:666px) and (max-width: 768px) {
        @include cardWrapper();
        .oi-form,
        .office-safety-form {
            padding: 0px;
            .show-grid {
                padding-bottom: 5px;
            }
        }
        .checkbox-input {
            display: inline-block;
        }
        .oi-report {
            .latitude,
            .longitude {
                @include coordsStyle();
            }
        }
    }

    @media screen and (width: 568px) {
        @include cardWrapper();
        .latitude,
        .longitude {
            @include coordsStyle();
        }
    }

    @media screen and (max-width: 320px) {
        .add-concern-modal {
            .modal-body {
                padding: 20px 0px;
            }
        }
        .summary-reports {
            .container {
                padding: 0px !important;
                .oi-report-card,
                .office-safety-report-card {
                    margin: 2.5px auto;
                }
            }
        }
    }

    @media screen and (max-height:414px) {
        .chart {
            width: 100%;
            .legends {
                height: 135px;
                text-align: left;
                margin: 20px 0;
                padding: 0px 5px;
            }
        }
    }


@import '../../constants.scss';
.group-members-list{
    width: 90% !important;
    margin: 0 auto;
    .table-col-tabtitle{
        text-align: center;
        font-size: 20px;
        color: yellow;
    }
    th{ color: yellow}
    th.table-col-unit-15 {
        width: 15%;
    }
    th.table-col-unit-20 {
        width: 20%;
    }
    th.table-col-unit-15 {
        width: 15%;
    }
    th.table-col-unit-20 {
        width: 20%;
    } 
    tr.member-detail {
        cursor: pointer !important;
        margin: 2%;
    }
    margin-bottom: 3%;
}
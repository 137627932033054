@import '../../constants.scss';
@import '../Group/addGroupForm.scss';
@import '../OIForm/oiForm.scss';
@mixin listPageAlert() {
    color: red;
    text-align: center;
    margin-top: 10px;
    margin-bottom: -20px;
}

.user-list-page-container {
    width: 800px;
}

.react-signature-button {
    color: white;
    border: none;
    background-color: #26a65b;
    width: 75px;
    border-radius: 5px;
    padding: 5px 0px;
}

.add-user,
.update-profile {
    padding: 20px;
    .show-grid {
        padding-bottom: 20px;
        .form-control {
            border-radius: 17px;
        }
    }
    .submit {
        display: block;
        margin: auto;
        width: 150px;
        font-size: 20px;
        position: relative;
        top: 10px;
    }
}

.users-list {
    .list-page {
        .user-alert {
            @include listPageAlert();
        }
        .reports-table {
            .heading {
                padding: 0px 0px 10px 10px;
            }
        }
    }
}

.user-details {
    padding: 20px;
    color: white;
    .show-grid {
        padding: 10px 10px;
        .key {
            text-align: right;
            color: $detailsPageKeyColor;
        }
        @include editButtonStyle();
    }
}

@media screen and (max-width: 666px) {
    .add-user,
    .update-profile {
        .show-grid {
            padding-bottom: 5px;
        }
    }
    .update-profile {
        .user-form {
            .show-grid {
                padding-bottom: 5px;
            }
        }
    }
}

@media screen and (min-width:667px) and (max-width: 767px) {
    .add-user,
    .update-profile {
        .show-grid {
            padding-bottom: 5px;
        }
    }
    .add-user,
    .update-profile {
        padding: 20px;
        .input {
            .form-control {
                width: 100%;
            }
        }
        .user-form {
            .show-grid {
                padding-bottom: 5px;
            }
        }
    }
}
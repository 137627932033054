.reference {
    padding: 20px;
    min-height: 84vh;
    button {
        color: rgb(255, 255, 255);
        margin: 0px 20px;
        border: none;
        background-color: #26a65b;
        font-size: 18px;
        height: 40px;
        border-radius: 17px;
        outline: none;
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
        &:hover {
            cursor: pointer;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        }
    }
    a {
        text-decoration: none;
        color: white;
    }
    .card-container {
        .col-sm-5,
        .col-xs-11 {
            float: none;
        }
        .card {
            padding: 0;
            margin: 4% 4.1%;
            //height: 290px;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.26);
            display: inline-table;
            img {
                width: 100%;
                //height: 232px;
                height: 180px;
            }
            .reference-text {
                height: 58px;
                font-size: 16px;
                vertical-align: middle;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;
                width: 100%;
                padding: 0 10px;
                color: #003152;
            }
            &:hover {
                cursor: pointer;
                box-shadow: 0 6px 10px rgba(0, 0, 0, 0.26);
            }
        }
    }
}

.show-edit-options2 {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .form-control{
        position: relative;
        &:hover {
            color: white;
        }
    }
}
.show-edit-options {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .fa {
        font-size: 61px;
        color: lightgray;
        margin: 15px;
        position: relative;
        &:hover {
            color: white;
        }
    }
    .fa-file {
        span {
            font-size: 14px;
            position: absolute;
            left: 0;
            z-index: 17;
            color: white;
            height: 100%;
            text-align: center;
            display: flex;
            top: 0;
            justify-content: center;
            align-items: center;
            color: lightgray;
            &:hover {
                color: black;
            }
        }
    }
    transition: all 0.5s;
}

.no-reference {
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 20px;
    flex-direction: column;
    button {
        padding: 0 20px;
        margin: 28px;
    }
}

.reference-doc-upload {
    .submit-button {
        text-align: center;
        padding: 25px;
    }
    button {
        color: rgb(255, 255, 255);
        margin: 0px 20px;
        border: none;
        background-color:#26a65b;
        font-size: 18px;
        height: 40px;
        border-radius: 17px;
        outline: none;
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
        &:hover {
            cursor: pointer;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        }
    }
    .reference-upload {
        padding: 20px;
        display: flex;
        background-color: #003152;
        margin-bottom: 1px;
        flex-direction: column;
        justify-content: center;
        .add-link-container {
            display: flex;
            .fa-check {
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                color: white;
                align-items: center;
                background-color: #26a65b;
                border-radius: 30px;
                margin-left: 10px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .url-display {
            .fa-trash {
                margin-left: 10px;
                color: white;
                font-size: 20px;
                padding: 5px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .dropzone-container {
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            .dropzone {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 200px;
                padding: 30px;
                flex-direction: column;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
                .fa-download {
                    font-size: 100px;
                    color:white;
                }
                p {
                    font-size: 16px;
                    color:yellow;
                }
                border: 1px solid white;
                &:hover {
                    cursor: pointer;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.50), 0 3px 6px rgba(0, 0, 0, 0.50);
                }
            }
        }
        .files-display {
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            padding: 20px;
            .file-details {
                width: 230px;
                flex-flow: column;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                flex-direction: column;
                flex-wrap: wrap;
                word-break: break-all; // height: 150px;
                position: relative;
                margin: 6px;
                text-align: center;
                transition: all 0.1s cubic-bezier(.25, .8, .25, 1);
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                .fa-times {
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: gray;
                    padding: 6px;
                    opacity: 0;
                    font-size: 23px;
                    background-color: lightgray;
                    border-bottom-left-radius: 12px;
                    transition: opacity 0.5s cubic-bezier(.25, .8, .25, 1);
                    visibility: hidden;
                }
                .row-one {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    width: 100%;
                    color:white;
                    .fa-file {
                        font-size: 50px;
                        padding: 5px;
                        color: white;
                    }
                    .file-name {
                        padding: 3px 5px;
                        border: 0.5px solid #d2d2d2;
                        border-radius: 10px;
                        font-size: 15px;
                    }
                }
                .row-two {
                    padding: 7px;
                    word-break: break-all;
                    color: white;
                }
                &:hover {
                    cursor: pointer;
                    .fa-times {
                        opacity: 1;
                        visibility: visible;
                    }
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
                    0 3px 6px rgba(0, 0, 0, 0.23);
                }
            }
        }
    }
}

.add-upload-reference {
    padding: 20px;
}
.reference-priority-input{
    font-size: 15px;
}
.is-searchable.is-focused:not(.is-open)>.Select-control {
    cursor: text;
}

.rrf-form {
    textarea {
        overflow-x: hidden;
    }
}

.basepage {
    .is-focused:not(.is-open)>.Select-control {
        border-color: #66afe9;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    }
}

.Select--multi .Select-value {
    border-radius: 17px !important;
}

.Select-placeholder {
    color: black !important;
}

@media screen and (max-width: 767px) {
    .rrf-form {
        position: relative;
        padding: 0px 8px;
    }
    .Select {
        left: 0px !important;
    }
}

@media screen and (width: 768px) {
    .rrf-form {
        position: relative;
        padding: 0px 8px;
    }
    .Select {
        left: 0px !important;
    }
}

.date-picker {
    width: 100%;
    border-radius: 30px;
    .react-date-picker__button {
        width: 100%;
        height: 33px;
        border-radius: 30px;
        border-color: #cccccc;
        background-color: white;
        padding: 0 10px;
        color:black;
        g {
            stroke: black;
        }
    }
    .react-date-picker__calendar {
        z-index: 1000;
        color:black;
    }
}


/* .date-picker {
    width: 100%;
    border-radius: 30px;
    .react-date-picker__button {
        width: 100%;
        height: 33px;
        border-radius: 30px;
        border-color: #cccccc;
        background-color: white;
        padding: 0 10px;
        color:black;
        g {
            stroke: black;
        }
    }
    .react-date-picker__calendar {
        z-index: 1000;
        color:black;
    }
} */
.react-date-picker__wrapper{border: unset !important;}
.react-date-picker__inputGroup{
    position: absolute;
    padding: 5px 10px !important;
}
.react-date-picker__calendar-button{
    display: block;
}
 .react-date-picker__calendar-button__icon.react-date-picker__button__icon{
     /* display: none; */
     width: 33px;
    height: 24px;
    float: right;
 }
 .select-dropdown > div{ cursor: pointer !important;}
 .select-dropdown /* > */ div{ border-radius: 17px !important; color: black; text-align: left;}
 
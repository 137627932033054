@import '../../../constants.scss';
.update-password {
    background-color: #003152;
    padding: 20px;
    .show-grid {
        padding: 10px;
    }
}

@media screen and (max-width:768px) {
    .update-password {
        .show-grid {
            padding: 5px;
        }
    }
}
@font-face {
    font-family: Font1;
    src: url('fonts/Font1.ttf');
}

@font-face {
    font-family: Font2;
    src: url('fonts/Font2.ttf');
}

@font-face {
    font-family: Font3;
    src: url('fonts/Font3.ttf');
}

@font-face {
    font-family: Font4;
    src: url('fonts/Font4.ttf');
}

@font-face {
    font-family: Font5;
    src: url('fonts/Font5.ttf');
}


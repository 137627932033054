.checklist-menu.col-sm-4:nth-child(3), .checklist-menu.col-sm-4:nth-child(6), .checklist-menu.col-sm-4:nth-child(9), .checklist-menu.col-sm-4:nth-child(12) {
    border-right: none; 
}
/* .checklist-menu.col-sm-4:nth-last-child(-n+3) {
    border-bottom: none;
} */
.checklist-menu.col-sm-6:nth-child(even) { 
    border-right: none;
}
.checklist-menu.col-sm-4:nth-child(4) {
    border-bottom: none;
}

/* .checklist-menu.col-sm-3:nth-last-child(-n+4) {
    border-bottom: none;
} */
/* .checklist-menu.col-sm-3:nth-child(4), .checklist-menu.col-sm-3:nth-child(8), .checklist-menu.col-sm-3:nth-child(12), .checklist-menu.col-sm-3:nth-child(16) {
    border-right: none;
}
.checklist-menu.col-sm-4:nth-child(7), .checklist-menu.col-sm-4:nth-child(8), .checklist-menu.col-sm-4:nth-child(9)  {
    border-bottom: none;
}
.checklist-menu.col-sm-3:nth-child(5), .checklist-menu.col-sm-3:nth-child(6), .checklist-menu.col-sm-3:nth-child(7), .checklist-menu.col-sm-3:nth-child(8) {
    border-bottom: none;
} */
/* .checklist-summary .actual-legend{ width: min-content;
    padding: 0px 10px;}
.checklist-summary .line-chart-legends .actual-legend span{
    position: initial;
} */

.checklist-summary .line-chart-legends .actual-legend {
    width: 20% !important;
}
.auditQuestion label{
    display: inline;
}
.width-height-100-textcenter{
    padding-bottom: 5px;
}
.scorePersent, .totalScore{ font-size: 18px; font-weight: 600 !important;}
.effect-input{cursor: pointer;}
.verify-JSA {
    position: relative;
    .button{ 
        margin: 0;
        position: absolute;
        top: 70%;
        left: 20%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        color: white;
        margin: 0px 20px;
        border: none;
        background-color: #26a65b;
        font-size: 18px;
        height: 40px;
        border-radius: 17px;
        outline: none;
        border-radius: 10px;
    }
}
.sequence-number-position{
    left: 55px;
    color: white;
}
.effect-splice label{display: hide !important;}
.checklist-title{text-align: center !important;}
.auditQuestion label{color: #fff !important; 
}
.auditQuestion .input-label{
    text-align: center ; 
}
#print{
    .checklist-title label{ color: gray !important; }
    .sequence-number-position{ left: 25px !important; color: black !important}
    .auditQuestion label{color: black !important}
    .effectiveness {color: black !important}
    .comments {color: black !important}
    .effect-splice label{display: show !important;}
    .effect-full label{display: hide !important;}
    .details-label label{ color: gray !important;}
}
.report-details { 
    .row-cell-height.col-12{
        margin-top: unset !important;
    }
}
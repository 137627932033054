@import '../../OIForm/oiForm.scss';
 
.basepage .radio-input:checked + label:after, .pdf-page .radio-input:checked + label:after {
    content: '';
    position: absolute;
    background: #26a65b;
    width: 12px;
    height: 12px;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.basepage .radio-input:checked + label:before, .basepage .radio-input:not(:checked) + label:before, .pdf-page .radio-input:checked + label:before, .pdf-page .radio-input:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
.radioInput {text-align: left !important;}  
.reporterLocationContent label{margin:4px !important;}
.mt15{margin-top:15px !important;}  
input,
input::-webkit-input-placeholder {
    font-size: 0.8em; 
}
[class*='-placeholder'] {
    font-size: 0.8em !important; 
}
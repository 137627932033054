@import '../../../constants.scss';
@import '../../JSAForm/jsaForm.scss';
.safety-walkthrough-assesment {

    .dropdown{
        display: inline; 
        button {
            padding: 0px 5px; 
        }
        .sequence-number-position{
            display: inline-block; 
            line-height: 25px;
        }
    }

    padding: 20px 30px;
    .show-grid {
        padding-bottom: 20px;
        color:#BFBFBF;
        .input {
            text-align: center;
            color:#BFBFBF;
        }
    }
    .persona-form-table {
        border-bottom: 1px solid black;
    }
    .action-label {
        color: yellow;
        padding-bottom: 5px;
    }
    .answer-label {
        text-align: right;
    }
    textarea {
        overflow-x: hidden;
        resize: none;
    }
    Label{
        //color:yellow;
    }
    Col{
        border:solid 1px white;
    }    
   .add-row {
        width: 20%;
        background-color: #4b77be;
        border: none;
        border-radius: 17px;
        color: white;
        height: 34px;
    }
    .table-buttons{
        margin-left: 33%;
        width: 100%
    }
   
}

.safety-addattribute_form{
    padding: 20px 30px;
    .show-grid {
        padding-bottom: 20px;
        color:#BFBFBF;
        .input {
            text-align: center;
            color:#BFBFBF;
        }
    }
    .persona-form-table {
        border-bottom: 1px solid black;
    }
    .action-label {
        color: yellow;
        padding-bottom: 5px;
    }
    .answer-label {
        text-align: right;
    }
    textarea {
        overflow-x: hidden;
        resize: none;
    }
    Label{
        color:yellow;
    }
    Col{
        border:solid 1px white;
    }
    .add-row {
        width: 40%;
        background-color: #4b77be;
        border: none;
        border-radius: 17px;
        color: white;
        height: 34px;
        margin-bottom: 20px;
    }
    .table-buttons{
        margin-left: 28%;
        width: 100%
    }    
    .margin-top-delete{
        margin-top:15px;
        cursor: pointer;
    }
}

.border-all{
    border:solid 1px silver;
}
.border-top{
    border-top:solid 1px silver;
}
.border-bottom{
    border-bottom:solid 1px silver;
}
.border-left{
    border-left:solid 1px silver;
}
.border-right{
    border-right:solid 1px silver;
}
.margin-left-right-0{
    margin-left:0px;
    margin-right: 0px;
}

.padding-bottom-0{
    padding-bottom: 0px;
}

.row-height{
    height:100px;
}
.customized-grid{
    text-align: center;
}

.row-cell-height{
    /* height: 35px; */
    height: auto;
}
.width-height-100-textcenter{
    width:100%;
    text-align: center;
    padding-top: 5px;
    height: 100%;
}
.sequence-number-position{
    /* margin-right: 10px; */
    /* float: right; */
    display: inline-block;
    position: absolute;
    line-height: 25px;
    left: 45px;
}
.total-score-input{
    width: 30%;
    margin-top: 5px;
    color:black;
    font-weight: bold;
}
.comment-input{
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    width: 300px;
}
.date-input{
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    // width: 120px;
    padding: 3px;
    color:black;
}
.width-height-100-textleft{
    width:100%;
    text-align: left;
    padding: 5px;
    height: 100%;
}
.color-white{
    color:white;
}

.radio-button-size{
    width:20px;
    height: 20px;
    margin-top:6px;
    cursor: pointer;
}

.row-addrow-section{
 margin-top: 25px;
    
}

@media screen and (max-width: 768px) {
    .personal-safety-form {
        .answer-label {
            text-align: left;
        }
    }
}

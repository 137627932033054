@import 'mixins.scss';
@import 'constants.scss';
.image-outer {
    position: fixed;
    z-index: 14;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    width: 100%;
    .center,
    .left,
    .right {
        background: transparent;
        text-align: center;
    }
    .close-image {
        position: absolute;
        color: white;
        font-size: 45px;
        text-align: right;
        right: 0;
        margin: 0;
        z-index: 100;
        padding: 10px 30px;
        &:hover {
            cursor: pointer;
        }
    }
    .image-gallery-left-nav,
    .image-gallery-right-nav {
        height: 100%;
        padding: 50px;
    }
    .image-gallery-slides {
        img {
            height: 80vh;
            width: auto;
        }
    }
    .image-gallery {
        top: 5%;
        position: relative;
    }
}

.fa.rename-file {
    position: absolute;
    line-height: 3;
    top: 0;
    color: green;
    text-align: center;
    width: 25px;
    &:hover {
        cursor: pointer;
    }
}

.delete-file {
    position: absolute;
    right: 10px;
    width: 30px;
    text-align: center;
    height: 100%;
    bottom: 2px;
    line-height: 2;
    font-size: 20px;
    z-index: 100;
    &:hover {
        cursor: pointer;
    }
}

.oi-report,
.jsa-report,
.incident-report,
.incident-investigative-report,
.office-safety-report, .modal-body {
    color:white;
    .display-image-wrapper {
        width: 90px;
        height: 75px;
        display: inline-block;
        margin: 0 5px;
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
        &:hover {
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16), 0 6px 6px rgba(0, 0, 0, 0.10);
            transition: all 0.1s cubic-bezier(.25, .8, .25, 1);
            border-radius: 5px;
            cursor: pointer;
        }
    }
}

.heading {
    color: $fontColor;
    margin: unset;
    padding-bottom: 10px;
    font-size: 16px;
    text-align: center;
}

@mixin transitionForConeTooltip($top) {
    top: $top;
    -webkit-transition: all 0.50s ease;
    -moz-transition: all 0.50s ease;
    -o-transition: all 0.50s ease;
    -ms-transition: all 0.50s ease;
    transition: all 0.50s ease;
    visibility: visible;
}

@mixin tooltipPosition {
    position: absolute;
    font-size: 14px;
    visibility: hidden;
    line-height: 45px;
    background: white;
    width: 76px;
    border: 1px solid #cecece;
    right: 30%;
}

.cone-labels {
    text-align: center;
    position: absolute;
    z-index: 10;
    width: $hundredPercent;
    right: 0px;
    //.label {}
    font-size: 11px;
    .label-1 {
        &:hover {
            .label-1-tooltip {
                top: 0px;
                -webkit-transition: all 0.50s ease;
                -moz-transition: all 0.50s ease;
                -o-transition: all 0.50s ease;
                -ms-transition: all 0.50s ease;
                transition: all 0.50s ease;
                visibility: visible;
            }
        }
    }
    .label-1-tooltip {
        @include tooltipPosition();
        top: 0px;
    }
    .label-2 {
        &:hover {
            .label-2-tooltip {
                top: 30px;
                -webkit-transition: all 0.50s ease;
                -moz-transition: all 0.50s ease;
                -o-transition: all 0.50s ease;
                -ms-transition: all 0.50s ease;
                transition: all 0.50s ease;
                visibility: visible;
            }
        }
        line-height: 44px;
    }
    .label-2-tooltip {
        top: 0px;
        @include tooltipPosition();
    }
    .label-3 {
        &:hover {
            .label-3-tooltip {
                top: 60px;
                -webkit-transition: all 0.50s ease;
                -moz-transition: all 0.50s ease;
                -o-transition: all 0.50s ease;
                -ms-transition: all 0.50s ease;
                transition: all 0.50s ease;
                visibility: visible;
            }
        }
        line-height: 42px;
    }
    .label-3-tooltip {
        @include tooltipPosition();
        top: 30px;
    }
    .label-4 {
        &:hover {
            .label-4-tooltip {
                top: 107px;
                -webkit-transition: all 0.50s ease;
                -moz-transition: all 0.50s ease;
                -o-transition: all 0.50s ease;
                -ms-transition: all 0.50s ease;
                transition: all 0.50s ease;
                visibility: visible;
            }
        }
        line-height: 44px;
    }
    .label-4-tooltip {
        @include tooltipPosition();
        top: 60px;
    }
    .label-5 {
        &:hover {
            .label-5-tooltip {
                top: 159px;
                -webkit-transition: all 0.50s ease;
                -moz-transition: all 0.50s ease;
                -o-transition: all 0.50s ease;
                -ms-transition: all 0.50s ease;
                transition: all 0.50s ease;
                visibility: visible;
            }
        }
        line-height: 58px;
    }
    .label-5-tooltip {
        @include tooltipPosition();
        top: 107px;
    }
    .label-6 {
        &:hover {
            .label-6-tooltip {
                top: 213px;
                -webkit-transition: all 0.50s ease;
                -moz-transition: all 0.50s ease;
                -o-transition: all 0.50s ease;
                -ms-transition: all 0.50s ease;
                transition: all 0.50s ease;
                visibility: visible;
            }
        }
        line-height: 58px;
    }
    .label-6-tooltip {
        @include tooltipPosition();
        top: 159px;
    }
    .label-7 {
        &:hover {
            .label-7-tooltip {
                top: 280px;
                -webkit-transition: all 0.50s ease;
                -moz-transition: all 0.50s ease;
                -o-transition: all 0.50s ease;
                -ms-transition: all 0.50s ease;
                transition: all 0.50s ease;
                visibility: visible;
            }
        }
        line-height: 60px;
    }
    .label-7-tooltip {
        @include tooltipPosition();
        top: 213px;
    }
}

.cone {
    height: 96%;
    width: $hundredPercent;
    position: absolute;
    top: 2px;
    right: 0px;
    padding-bottom: 10px;
    .mti:hover {
        .cone-labels {
            .label-1-tooltip {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

@media screen and (min-width:320px) and (max-width:736px) {
    .heading {
        display: none;
    }
    .report-row {
        padding: 15px 0px;
        text-align: left;
        border-bottom: 1px solid #BDBDBD;
        width: 100%;
        margin: auto !important;
        &:hover {
            background-color: white !important;
        }
        .col-sm-1 {
            .dropdown {
                position: absolute;
                left: $hundredPercent;
                right: -1em;
                bottom: -2em;
                padding-top: 10px;
                top: 0.5em;
                .dropdown-menu {
                    left: -150px !important;
                    top: 30px;
                }
            }
            .serial-no {
                display: none !important;
            }
            .menu-items {
                left: 75%;
                top: 57px;
                width: 100px;
            }
        }
        .col-sm-4,
        .col-sm-3,
        .col-sm-2 {
            width: 80%;
            padding: 0px !important;
        }
    }
    .image-outer {
        .image-gallery-slides {
            img {
                height: 28vh;
                position: relative;
                top: 10%;
                width: auto;
                padding: 0px;
            }
        }
        .image-gallery {
            position: relative;
            top: 25%;
        }
    }
}

@media screen and (width: 568px) {
    .image-outer {
        img {
            height: 100vh;
            position: relative;
            top: 10%;
            padding: 18px;
        }
    }
}

@media screen and (max-width: 320px) {
    .image-outer {
        img {
            height: 57vh;
            position: relative;
            top: 10%;
            padding: 18px;
        }
    }
}

@media screen and (min-width:667px) and (max-width:736px) {
    .image-outer {
        img {
            height: 80vh;
            position: relative;
            top: 10%;
            padding: 18px;
        }
    }
}

@media (max-width: 768px) {
    .cone-labels {
        .label-2 {
            line-height: 59px;
        }
        .label-3 {
            line-height: 18px;
        }
        .label-5 {
            line-height: 28px;
        }
    }
}

@media (max-height: 768px) {
    .jsa-report {
        .button {
            position: relative;
            top: 25px
        }
    }
}



.danger-alert{
    font-size: 24px;
}
@import 'components/OIForm/oiForm.scss';
@import 'constants.scss';
@import './generals.scss';
@include uploadImage();

@font-face {
    font-family: RobotoSlab-Regular;
    src: url('fonts/RobotoSlab-Regular.ttf');
}

body {
    font-family: RobotoSlab-Regular !important;
}
@mixin notificationOnPhone {
    .notification-badge {
        position: absolute;
        width: 18px;
        color: black;
        background-color: white;
        border-radius: 17px;
        font-size: 11px;
        right: auto;
        margin-left: 5px;
    }

    .notification-badge.user {
        top: 10px;
        display: block;
        left: 60%;
    }
}

.mui-card {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    padding: 5px 0;
    background-color: white;
}

.notification.btn {
    text-align: left;
}

textarea {
    overflow-x: hidden;
}

fieldset {
    padding: .35em .625em .75em;
    margin: 0 2px;
    border: 1px solid silver;
}

.notification-badge {
    position: absolute;
    right: 15px;
    width: 25px;
    background-color: white;
    border-radius: 17px;
    font-size: 14px;
    color: black;
    text-align: center;
}

.notification-badge.user {
    right: -5px;
}

.notification-link {
    padding: 5px;
    color: #f90000;
    text-decoration: none;
    display: block;
    font-weight: 600;

    &:hover,
    &:focus {
        text-decoration: none;
        cursor: pointer;
        color: #980303;
        opacity: 1 !important;
    }
}

button {
    outline: none;
}

label {
    cursor: pointer;
}

@mixin emptyField {
    border-color: #fd0000 !important;
    border-radius: 17px;
    outline: 0;
    border: 2px solid #fd0000;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6) !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6) !important;
}

.notification-panel {
    top: 120px !important;
    border: none !important;
    border-radius: 0 !important;
    color: white;
    padding: 0 !important;
    transform: translateX(10px);
    max-height: 250px;
    overflow: auto;
    .arrow {
        border: none !important;

        &::after {
            border: none !important;
        }
    }

    .popover-content {
        height: 300px;
        background-color: black;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0px;
            visibility: hidden;
        }
    }

    &::-webkit-scrollbar {
        width: 0px;
        /* remove scrollbar space */
        background: transparent;
        /* optional: just make scrollbar invisible */
    }

    /* optional: show position indicator in red */
    &::-webkit-scrollbar-thumb {
        background: #FF0000;
    }
}

@mixin basepage {
    margin: auto;
    background-color: #003152;
    border-radius: 5px;
    height: auto;
    word-wrap: break-word;

    .form-control {
        border-radius: 17px;
        color: black;
    }

    .empty-field {
        @include emptyField();

        &:focus {
            @include emptyField();
        }

        .Select-control {
            border: none !important;
        }
    }

    .Select,
    .empty-field {
        left: 0px;

        .Select-control {
            height: 34px;
            border-radius: 17px;
        }
    }
}
.empty-field {
    @include emptyField();

    &:focus {
        @include emptyField();
    }

    .Select-control {
        border: none !important;
    }
}


@mixin button() {
    color: rgb(255, 255, 255);
    margin: 0px 20px;
    border: none;
    background-color: #26a65b;
    font-size: 18px;
    height: 40px;
    border-radius: 17px;
    outline: none;
}

@mixin modalButtons {

    .modal-buttons,
    .edit-button {
        .button {
            width: $hundredPercent - 60% !important;
            margin: 0px 5px;
        }
    }
}

#safeconnect>div {
    height: $hundredPercent;
}

.background {
    background-image: url(images/colimageuser.svg);
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 12%;
    min-height: $hundredPercent;
    position: relative;

    .nav-footer {
        background-color: rgba(130, 159, 203, 0.5);
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 72px;

        .nav-dropdown {
            list-style: none;
            position: relative;
            height: 100%;
            float: right;

            .dropdown-toggle {
                color: white;
                top: 25px;
                width: 50px;
                height: 60px;
                text-decoration: none;
                background-color: transparent;
                font-size: 18px;
                position: relative;
                margin-right: 20px;
            }

            .dropdown-menu {
                left: 20px;
                bottom: 50px;
                background-color: black;

                .menu-item {
                    a {
                        color: white;
                        outline: none;

                        &:hover {
                            background-color: black;
                        }
                    }
                }
            }
        }
    }
}

label {
    font-weight: normal !important;
}

.confirmation-modal,
.golden-rules,
.create-custom-notification-modal {
    .modal-body {
        font-size: $textSize;
        padding: 25px 40px;
        color: white;

        .is-focused:not(.is-open) {
            .Select-control {
                border-color: #66afe9;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
            }
        }

        .form-control,
        .Select-control {
            border-radius: 17px;
        }

        .modal-buttons,
        .edit-button {
            text-align: right;

            .button {
                width: $buttonWidthLarge;
                @include button();
                margin: 15px 10px 10px 10px;
            }
        }
    }

    .edit-button {
        text-align: center;

        .button {
            width: $buttonWidthLarge;
            @include button();
            margin: 15px 10px 10px 10px;
        }
    }
}

.create-custom-notification-modal, .followup-list-modal, .followup-form-model, .covid19-assessment-modal {
    .modal-title {
        color: yellow;
    }

    .close {
        color: #d82323;
    }

    .show-grid {
        padding: 10px 0;
    }
}
.followup-list-modal{
    width: 100%;
}
.confirmation-modal.checklist-attribute-form{
    .modal-dialog{
        max-width: 660px; 
    } 
}
.model-50w{
    /* max-width: 600px !important; */
    min-width: 50%;
}
.Select-menu-outer {
    text-align: left !important;
}

.print-button {
    .button {
        width: $buttonWidthLarge;
        @include button();
    }
}

.button {
    &:hover {
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16), 0 6px 6px rgba(0, 0, 0, 0.10);
    }
}

.form-button {
    margin: 0 auto;
    width: $hundredPercent;
    text-align: center;

    .button {
        width: $buttonWidthLarge;
        @include button();

        &:hover {
            transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16), 0 6px 6px rgba(0, 0, 0, 0.10);
        }
    }
}

.weather-button-container {
    position: fixed;
    bottom: 20px;
    text-align: center;
    right: 20px;
    background-color: white;
    padding: 15px 20px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.5);

    &:hover {
        box-shadow: 0 10px 30px rgba(140, 140, 140, 0.5);
        cursor: pointer;
    }

    img {
        height: 10vh;
        padding-bottom: 10px;
    }

    .info {
        text-align: center;
        margin-left: -20px;
        margin-right: -20px;
        padding-top: 11px;
        border-top: 1px solid #e8e8e8;
        font-size: 20px;
    }
}

.basepage {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.basepage,
.pdf-page {
    @include basepage();
    width: $basepageWidth;
    display: block;
    top: 40px;
    position: relative;

    .background {
        background-color: #003152;
    }

    .input-label {
        text-align: right;
    }

    @include checkBox();

    .form-title {
        text-align: center;
        font-size: 30px;
        color: yellow;
    }

    .border {
        display: block;
        width: 80%;
        border-bottom: 1px solid white;
        margin: auto;
        padding-top: 5px;
    }

    .rrf-form {
        position: relative;
    }

    .export-button-container {
        .button {
            width: $buttonWidthLarge;
            @include button();
        }
    }
}

@mixin goldenRulesModalFormButtons {
    .edit-button {
        text-align: center;
        width: 100%;

        .button {
            width: 40%;
            display: inline-block;
        }
    }
}

/**
* @media is used to style the pages for small and tablet devices this is applied on the
* provided width or height.
*/

@media screen and (max-width: 767px) {
    .weather-button-container {
        display: none;
    }

    @include modalButtons();
    @include notificationOnPhone();

    .form-button .button {
        vertical-align: top;
        width: 40%;
        margin: 0px 12px;
        font-size: $buttonFontSizeSmall;
    }

    .basepage {
        .export-button-container {
            .button {
                vertical-align: top;
                width: 40%;
                margin: 0px 12px;
                font-size: $buttonFontSizeSmall;
            }
        }
    }

    .error-page {
        padding: 40px 30px !important
    }

    .notification.btn {
        text-align: center;
    }

    .checkbox-input {
        position: absolute !important;
        right: 0px;
    }

    .background {
        padding-bottom: 30%;
        position: relative;
    }

    .golden-rules,
    .add-concern-modal {
        .modal-body {
            @include goldenRulesModalFormButtons();
            font-size: $fontSizeSmall;
            padding: 25px 20px;
        }

        .table-buttons {
            .add-row {
                width: 100%;
            }
        }
    }

    .confirmation-modal {
        .modal-body {
            font-size: $fontSizeSmall;

            .modal-buttons,
            .edit-button {
                .button {
                    width: 46% !important;
                    margin: 15px 5px 5px 5px;
                    font-size: $buttonFontSizeSmall;
                }
            }
        }
    }

    .basepage {
        @include basepage();
        padding: 0px 0px 20px 0px;
        width: 90%;
        top: 0px;
        font-size: $fontSizeSmall;

        .page-title {
            .form-title {
                font-size: $fontSizeMedium;
            }

            .border {
                width: 100%;
            }
        }

        .form-control {
            font-size: $fontSizeSmall;
        }

        .show-grid {

            .col-sm-4,
            .input-label {
                text-align: left !important;
                padding-left: 15px;
                padding-bottom: 3px;
                font-size: $fontSizeSmall;
            }
        }
    }
}

@media screen and (width: 768px) {
    @include notificationOnPhone();
    @include modalButtons();

    .basepage {
        padding: 0px 0px 20px 0px;
        font-size: $fontSizeSmall;

        .page-title {
            .form-title {
                font-size: $fontSizeMedium;
            }

            .border {
                width: 80%;
            }
        }

        .show-grid {
            padding-bottom: 20px;

            .col-sm-4 {
                text-align: left;
                padding-left: 16px;
                padding-bottom: 3px;
                text-align: right;
            }
        }
    }

    .golden-rules,
    .add-concern-modal {
        .modal-body {
            @include goldenRulesModalFormButtons();
            font-size: $fontSizeSmall;
            padding: 25px 20px;
        }

        .table-buttons {
            .add-row {
                width: 100%;
            }
        }
    }
}
#print .office-safety-wrapper{
    background-color: white;
}
#print {
    background-color: white;

    .details-label, .report-row {
        color: gray;
    }

    .details-value, .clickable-list-item {
        color: black;
    }

    .filter-container {
        display: none;
    }

    .form-title {
        color: #88A4CE !important;
    }

    .table-label {
        color: #88A4CE;
    }

    .office-safety-comments {
        padding: 18px;
    }
    .step-data{color: black}
}

.details-value {
    color: white;
}

.details-label {
    color: yellow;
}

@media screen and (max-height: 414px) {
    @include notificationOnPhone();
    @include modalButtons();

    .golden-rules,
    .add-concern-modal {
        .table-buttons {
            .add-row {
                width: 100%;
            }
        }
    }

    .basepage {
        width: 73%;
    }
}


@media (max-width:768px) {
    input[type=file] {
        left: 0 !important;
    }
}
.closeCallSummary .line-chart-legends .actual-legend{
    width: 25% !important;
}
.close-call-followup-warning{ color: #ffc107; border-color: #ffc107;
    &:hover,
    &:focus {
        text-decoration: none;
        cursor: pointer;
        color: white;
        opacity: 1 !important;
        background-color: #ffc107
    }
}
.close-call-followup-closed{ color:  green; border-color: green;
    &:hover,
    &:focus {
        text-decoration: none;
        cursor: pointer;
        color: white;
        opacity: 1 !important;
        background-color: green
    }
}
.close-call-followup-open{ color:  red; border-color: red;
    &:hover,
    &:focus {
        text-decoration: none;
        cursor: pointer;
        color: white;
        opacity: 1 !important;
        background-color: red
    }
}


.e-signature{ 
    text-align: center;
    .m-signature {
        .btn-default {
            width: 85px !important;
            font-size: 14px;
            color: rgb(84, 121, 175);
            border: 1px solid rgb(84, 121, 175);
            background-color: white;
            padding: 4px;
        }
    } 
    .m-signature-pad--body {
        text-align: center;
        background-color: white;
        margin: 0px 14%; 
        border-radius: 5px;
        canvas { 
            border: 1px solid;
            margin-top: 7px;
            height: 55px !important; 
            width: 300px !important;
        }
    } 
    .m-signature .btn{
        margin-top: 10px;
    }   
    .m-signature-pad--footer {
        padding-left: 20%;
        float: left;
        .btn-default {
            width: 85px !important;
        }
    }
}
.e-signature.col-sm-4{ 
    .m-signature-pad--body {
        canvas { 
            width: 200px !important;
        }
    }
    .m-signature-pad--footer {
        padding-left: 14.5%;
    }
    .auto-e-signature-generator { 
        .signature-container {
            margin: 0px 0%;
        }
        .footer-container{
            margin: 3% 0% 0% 0%;
        }
    }
}
.auto-e-signature-generator {
    .App {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .Select {
        width: 100px;
        text-align: left;
    }
    .signature-container {
        height: 65px !important; 
        margin: 0px 15%;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        min-width: 300px;
    }
    .footer-container {
        margin: 3% 15% 0% 5%;
        /* display: flex; */
        align-items: center;
        justify-content: space-between;
        min-width: 300px
    }
} 
.e-signature.user-form, .e-signature.investigation-follow-up {
    .m-signature-pad--body {
        width: 310px !important;
        margin: unset !important;
    }
    .m-signature-pad--footer {
        padding-left: 5% !important;
    }
    .auto-e-signature-button{
        margin-left: 10%;
    }
    .auto-e-signature-generator{
        .signature-container{
            width: 310px !important;
            margin: unset !important;
        }
        .footer-container {
            margin: 10px 0px 0px 0px !important;
            width: 290px;
        }
    }
    .manual-e-signature-button{
        margin-left: 10%;
    }
} 
.e-signature.investigation-follow-up {
    color: black;
     
    .manual-e-signature-button{
        text-align: unset !important;
        margin-left: 10%;
    }
    .auto-e-signature-button{
        text-align: unset !important;
        margin-left: 10%;
        .btn{
            width: 220px;
        }
    }
}




/* .follow-up-signature .m-signature-pad--body { 
    width: 245px;
    }
.follow-up-signature .m-signature-pad--body canvas {
    height: 56px !important; 
    width: 235px !important;
    border: 1px solid #000000;
    margin-top: 7px;
} 
.follow-up-signature .btn{
    margin-top: 10px;
} 
 */
.closecall-followup-button, .closecall-followup-button:focus{background-color: green; color:white;border-color: white;}
.wide-list-page .add-new-report{right:9.5% !important;}
.wide-list-page .summary-page{right:9.5% !important;}
.oi-report-list .add-new-report{right:3% !important;}
.oi-report-list .summary-page{right:3% !important;}
.videoDangerAlert p{ line-height: 12pt; } 
.video-wrapper{ width: 155px !important; height: 125px !important; margin: 0px 5px; box-shadow: #0b3f61 0px 3px 3px;  }
.no-video{width:90px !important; height: 75px !important;}
.closecall-videos, .closecall-images { text-align: center !important;}
.closecall-videos .video-wrapper{display: inline-block;}
video { outline: none !important; }
.video-wrapper .video-name{font-size: 11px !important; text-align: center !important; }
.un-authorized-content{color: white; margin-top: 20px; }
.user-bulk-upload-grid{padding: 20px 0px;  border-bottom: 1px solid silver;}
.bulk-file-upload{ height:  38px ;
    width: 136px;
    opacity: 0;
    position: absolute;
    cursor: pointer; margin-top: -40px;
    left: 5%;}
.bulk-file-upload-style{    line-height: 16px; width: 130px; text-align:center;
    padding: 6.5px 16px;
    background: white;
    color: #26a65b;
    border-radius: 17px;
    border: 1px solid #26a65b;}
.bulk-file-upload-style label{margin-bottom: 0px !important;}
.filter-container {
    /* display: flex; */
    align-items: flex-end;
    justify-content: space-evenly;
    color: white;
    flex-wrap: wrap;
    padding: 20px 0px;

    .filter-button {
        border: none;
        padding: 7px;
        background-color: #7ab57a;
        color: white;
        border-radius: 8px;
        width: 150px;
    }
    .filter-reset-button {
        border: none;
        padding: 7px;
        background-color: #ee0606;
        color: white;
        border-radius: 8px;
        width: 150px;
    }
}
.office-safety-comments .report-row .clickable-list-item{padding: 10px 0px !important;}
.office-safety-comments .filter-button, .office-safety-comments .filter-reset-button{width: 70px !important; margin-top: 20px;}
.field-disabled{cursor: not-allowed !important;}
ul .dropdown-menu .item{
    outline-color: unset;
}
/*Report Filter */
.report-filter-container {
    padding: 10px 20px 0px 20px; width: 100%;
    .input{
        color: #FFFF00;
    }
    .doneButton{
        color: white;
        border: 1px solid #5479AF; background-color: rgb(38, 166, 91);
        /* margin-left: 20px; margin-right: 20px; */ margin-top: 20px; outline-color: unset;
    }
    .clearButton{
        color: white;
        border: 1px solid #5479AF; background-color: rgb(255, 122, 122);
        /* margin-left: 20px; margin-right: 20px; */ margin-top: 20px; margin-left: 5px; outline-color: unset;
    }
}
.text-center{text-align: center;}
.closeButton{color: rgb(84, 121, 175) !important;
    border: 1px solid #5479AF;
    background-color: white !important;}
.checklist-wrapper .process-buttons{margin-top: 22px;}
.font-success{color: green;}
.font-danger{color: red;}
.text-18{ font-weight: 600;
    font-size: 18px;}
.title-container span.border{border: unset !important; border-bottom: 1px solid white !important;}
.modal-content{
    background-color:#003152;
}
button.close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0; font-size: 21px;
}

.linker{cursor: pointer !important;}
.mt5{margin-top: 5px}
.mt5percent{margin-top: 5% !important;}
.mt10{margin-top:10px !important;}
.mt10percent{margin-top:10% !important;}
.mt-10{margin-top:-10px !important;}
.mt20{margin-top: 20px;}
.mb10{margin-bottom: 10px; } 
.ml10{margin-left: 10px;}
.mr10{margin-right: 10px;}
.mt20{margin-top: 20px;}
.m-l10{ margin-left: 10px !important;  }
.p0{padding: 0px !important;}
.p10{padding: 10px !important;}
.pl0{padding-left: 0px !important;}
.pt10{ padding-top: 10px;}
.l50{left: 50px !important;}
.floatR{float: right;}
.react-date-picker{color: black;}
.hide{display: none !important;}
.print-logo{display: none;}
#print .print-logo{display: flex; }
.modal-title{ color: yellow; }

.print-preview-outer{
    .modal-header{  
        .close{
            color: red !important; 
            font-size: 30px;
            font-weight: 600;
        }
    }
}
@page { 
    margin: 0; 
    margin: 10px; 
}
@media print {
    .navbar-expand.navbar-light {
        display: none;
    }
    .export-button-container{display: none;}
    .print-logo{display: flex;
        .display-image-wrapper{
            width: 150px !important;
        }
    }
    .details-label, .legend-header, .sub-legend-header, .form-title, .key-data, .key-data label{
        color: black !important;
    }
    .details-value{
        color: gray;
    }
    .risk-assessment .res2 {
        color: #fff !important;
    }
    .report-details {
        .checklist-title label, .details-label label, .yellow-color-title {
            color: black !important;
        }
    }
    .pagebreak{
        page-break-before: always;
    }
    .google-map-content div{
        /* height: 100% !important; */
    }
    .highlight-coordinate-row{
        color: #fff !important;  
    }
    .coordsPointerCss{  color: #fff !important;  }
    
}
.print-preview-outer .oi-report-summary .legends {
   right: 0;
}
.moc-status-count{
    margin-top: 10px;
}  
.moc-status-count.default{
    background-color: #b9aeae !important;
    border-color: #b9aeae;
}
.moc-status-count.danger{
    background-color: #f71717 !important;
    border-color: #f71717;
}
.moc-status-count.success{
    background-color: #008000 !important;
    border-color: #008000;
}
.highlight-coordinate-row{
    color: #a4bf43 !important;  
}
.coordinate-highlight{ color: white;}
.ta-right {text-align: right !important;}
.excel-export-button{
    color: rgb(255, 255, 255);
    border: none;
    background-color: rgb(38, 166, 91);
    padding: 3px;
    border-radius: 5px;}
.safeconnect-form Legend {
    color: yellow !important;
}
.profile-qr{
    height: 220px;
    width: 170px;
    svg{
        height: auto;
        /* margin: 0px auto; */  
        border: 3px solid #ffffff;
    }
    .qr-print{
        text-align: center;
        button{
            color: green;
            margin-top: 10px;
        } 
    }
}
@media print {
    .user-details {
        .non-print {
            display: none;
        }
        .print-qr, .profile-qr {
            text-align: center;
        }
    }
}


.table{
    --bs-table-bg: initial;
    .form-title.yellow-center{
        th{
            color: yellow !important;
        }
        
    }
    tr.user-data{
        td{
            color: #ffff !important;
        }
    }
} 
.texas811{
    position: absolute !important;
    right: -12%;
    margin-top: 7.5%;
    border: none !important;
    box-shadow: 0 0 60px rgb(0 0 0 / 16%), 0 6px 77px rgb(0 0 0 / 10%) !important;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    background-color: #0066ab !important;
    border-radius: 120px !important;
} 
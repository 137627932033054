.safeconnect-form {
    padding: 20px 0;
    .action-label {
        text-align: left;
    }
    textarea {
        border-radius: 40px !important;
    }
    br {
        display: none;
    }
    fieldset {
        padding: 20px;
        border-radius: 40px;
    }
    .single-checkbox {
        padding: 10px;
    }
    .radio-buttons {
        position: relative;
        left: 5px;
        display: inline-block;
        .radio-input:not(:checked)+label,
        .radio-input:checked+label {
            bottom: 0px;
            top: -22px;
            left: 6px;
        }
    }
    legend {
        margin-bottom: 20px;
        margin-left: 20px;
        font-size: 18px;
        line-height: inherit;
        color: #333;
        border-bottom: none;
        width: auto;
    }
    .col-xs-12 {
        padding: 0 !important;
    }
    .form-top-fieldset {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
    }
    .form-bottom {
        padding: 0;
        border-right: 1px solid #c1c1c0;
        margin-right: 2px;
        border-bottom-right-radius: 46px;
        border-left: 1px solid #c1c1c0;
        margin-left: 2px;
        border-bottom-left-radius: 46px;
    }
    .form-bottom-fieldset {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-left: none;
        border-right: none;
    }
    .location-input {
        background-color: white !important;
        cursor: pointer;
    }
    ::-webkit-input-placeholder { /* Chrome */
        color: black;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: black;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: black;
        opacity: 1;
    }
    :-moz-placeholder { /* Firefox 4 - 18 */
        color: black;
        opacity: 1;
    }
}

@media (min-width: 769px) {
    .reports-page {
        width: 1078px;
    }
    .create-custom-notification-modal {
        .show-grid {
            display: flex;
            .custom-notification-input {
                float: none;
                display: inline-block;
            }
            .custom-notification-label {
                text-align: right;
                float: none;
            }
        }
    }
    .safeconnect-form {
        padding: 20px 30px;
        width: 1078px;
        background-color:#003152;
        br {
            display: block;
        }
        .single-checkbox {
            width: 31.33%;
            padding: 15px;
            text-align: center;
            border: 1px solid #cccccc;
            margin: auto;
            border-radius: 40px;
        }
        .two-buttons {
            .button-container {
                padding: 8px;
            }
            .first {
                border-right: 1px solid #c1c1c0;
            }
            .input-label {
                text-align: right;
            }
            .work-related {
                padding: 5px;
                text-align: left;
            }
        }
        .three-buttons {
            .input-label {
                text-align: right;
            }
            .work-related {
                padding: 0;
                top: 5px;
            }
        }
        .single-button {
            border: 1px solid #c1c1c0;
            margin: 0 0 20px 0;
            border-radius: 59px;
            padding: 15px;
            .input-label {
                text-align: right;
            }
            .work-related {
                top: 5px;
                text-align: left;
            }
        }
        .checkbox-row {
            border: 1px solid #c1c1c0;
            padding: 0 !important;
            margin: 0 30px 20px 0;
            text-align: center;
            border-radius: 30px;
            .col-sm-4 {
                padding: 15px 0 10px 0px;
            }
            .checkbox-center {
                border-right: 1px solid #c1c1c0;
                border-left: 1px solid #c1c1c0;
            }
        }
        .incident-radio-buttons {
            .checkbox-label {
                top: 0px;
            }
        }
        .injury-radio-buttons {
            .checkbox-label-one {
                padding-top: 15px;
            }
            .work-related {
                line-height: 62px !important;
            }
            .checkbox-center {
                padding: 22px;
                /* height: 72px; */
            }
            .checkbox-right {
               /*  height: 72px; */
                padding: 22px;
            }
        }
        .date-input,
        .time-input {
            padding: 0 15px !important;
        }
        .image-upload-container {
            text-align: center;
            .input-label {
                text-align: center;
            }
        }
        .input-label {
            text-align: center;
        }
        .file-upload {
            text-align: center;
        }
        .direct-cause {
            text-align: right;
        }
    }
}

@media (min-width: 768px) and (max-width:1024px) {
    .safeconnect-form {
        width: 90%;
        .input-label {
            text-align: center;
        }
    }
}
form { 
    .form-title-container { 
        /* padding: 10px 10px; */
        /*this makes the child divs relative to the parent*/
        width: 100%;
        position:relative;
    } 
    .form-title-mic { 
        position:absolute; 
        padding-top: 5px;
        display: inline-block;
        right: 30px; 
        color: red;
        font-size: 20px;
        cursor: pointer;
        i .fa-microphone.disabled {
            color: green;
        }
    }
    .form-title-container.no-padding {
        padding: unset !important;
        .form-title-mic{
            right: 10px !important;
            top: 2px !important;
        }
    }
    .recording-inprogress { 
        position: fixed;
        top: 20px;
        right: 130px;
        font-size: 50px;
        background-color: #f2b5b5e8; 
        border-radius: 20px;
        .img-responsive{
            height: 100px;
            width: 190px;
        }
    }
}
.checklist-menu.col-sm-4:nth-child(3), .checklist-menu.col-sm-4:nth-child(6), .checklist-menu.col-sm-4:nth-child(9), .checklist-menu.col-sm-4:nth-child(12) {
    border-right: none; 
}
/* .checklist-menu.col-sm-4:nth-last-child(-n+3) {
    border-bottom: none;
} */
.checklist-menu.col-sm-6:nth-child(even) { 
    border-right: none;
}
.checklist-menu.col-sm-4:nth-child(4) {
    border-bottom: none;
}

/* .checklist-menu.col-sm-3:nth-last-child(-n+4) {
    border-bottom: none;
} */
/* .checklist-menu.col-sm-3:nth-child(4), .checklist-menu.col-sm-3:nth-child(8), .checklist-menu.col-sm-3:nth-child(12), .checklist-menu.col-sm-3:nth-child(16) {
    border-right: none;
}
.checklist-menu.col-sm-4:nth-child(7), .checklist-menu.col-sm-4:nth-child(8), .checklist-menu.col-sm-4:nth-child(9)  {
    border-bottom: none;
}
.checklist-menu.col-sm-3:nth-child(5), .checklist-menu.col-sm-3:nth-child(6), .checklist-menu.col-sm-3:nth-child(7), .checklist-menu.col-sm-3:nth-child(8) {
    border-bottom: none;
} */
/* .checklist-summary .actual-legend{ width: min-content;
    padding: 0px 10px;}
.checklist-summary .line-chart-legends .actual-legend span{
    position: initial;
} */

.checklist-summary .line-chart-legends .actual-legend {
    width: 20% !important;
}
.checklist-delete{color: red !important; font-size: 25px; margin-top: 10px; margin-right: 10px; text-align: right;}
@import '../../../constants.scss';
@mixin input {
    display: block;
    margin: auto;
    width: 250px;
    /* padding-left: 8px; */
    border-radius: 17px;
}

$buttonColor: #4b77be;
$fontColor: #B9B9B9;
$placeholderMargin: 100px;
body,
html {
    margin: 0;
    padding: 0;
    line-height: 1.5em;
    height: 100%;
    width: 100%;
}

#safeconnect {
    height: 100%;
    width: 100%;
}

@mixin button {
    width: 65%;
    height: 50px;
    text-align: center;
    display: block;
    background-color: $buttonColor;
    border: none;
    border-radius: 50px;
    color: white;
    font-size: 20px;
    display: block;
    margin: auto;
    margin-top: 9%;
}

@mixin formFields() {
    .button-loading {
        @include button();
        background-color: #B2C6E4;
        cursor: not-allowed;
        div {
            margin-top: 4%;
        }
    }
    .title {
        text-align: center;
        font-size: 34px;
        padding-top: 25px;
    }
    .success-alert-alert {
        color: green;
        text-align: center;
    }
    .username {
        @include input();
        margin-top: -5%;
        //top: 75px;
        &:hover {
            outline: none;
        }
        &:focus {
            outline: none;
        }
    }
    .password {
        @include input();
        margin-top: 5%;
        &:hover {
            outline: none;
        }
        &:focus {
            outline: none;
        }
    }
    .is-focused:not(.is-open) {
        .Select-control {
            border-color: #66afe9;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
        }
    }
    .Select {
        @include input();
        padding-left: 0px;
        margin-top: 5%;
        .Select-control {
            border-radius: 17px;
        }
    }
    .button {
        @include button();
        &:hover {
            cursor: pointer;
        }
        &:focus {
            outline: none;
        }
    }
     ::-webkit-input-placeholder,
     :-ms-input-placeholder,
     ::-moz-placeholder,
     :-moz-placeholder {
        color: $fontColor;
        margin: $placeholderMargin;
    }
    .login-error {
        text-align: center;
        color: red;
    }
}

.login-form {
    background-image: url('../../../images/conImage.jpg') !important;
    padding: 100px 0px;
    background-repeat: no-repeat;
    height: 100%;
    background-size: cover;
    background-attachment: fixed;
    .empty-field {
        border-color: #F44336;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #F44336;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #F44336;
    }
    .failed,
    .success,
    .alert-field {
        margin-top: 10px;
        height: 24px;
    }
    .login {
        width: 350px;
        padding-bottom: 20px;
        position: relative;
        top: 10%;
        margin: auto;
        border-radius: 50px;
        background-color: #003152;
        @include formFields();
    }
    .sign-up-option {
        text-align: center;
        padding-top: 18px;
        color: white;
        .sign-up-text {
            color: #88A4CE;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .sign-up {
        width: 350px;
        padding-bottom: 20px;
        ;
        position: relative;
        top: 10%;
        margin: auto;
        border-radius: 50px;
        background-color: white;
        @include formFields();
        .sign-up-input {
            border-radius: 35px;
            margin: auto;
            width: 80%;
        }
    }
}

.sign-up-form {
    height: auto;
}
.forgot-password {
    cursor: pointer; 
    color: #88A4CE !important;
}

@media screen and (width: 768px) {
    .login-form {
        background-size: 3500px;
        .sign-up {
            width: 80%;
        }
        .login {
            width: 400px;
            .title {
                font-size: 40px;
            }
            .username,
            .password,
            .Select {
                font-size: 20px;
                width: 300px;
            }
            .button {
                width: 65%;
                font-size: 26px;
            }
        }
    }
}

@media screen and (max-height:768px) {
    .login-form {
        margin: auto;
        .login {
            top: 10%;
            width: 293px;
            .title {
                font-size: 30px;
            }
            .username,
            .password,
            .Select {
                font-size: 14px;
                width: 200px;
            }
            .button {
                width: 65%;
                font-size: 21px;
            }
        }
    }
}

@media screen and (max-width: 414px) {
    .login-form {
        background-size: 1550px;
        .sign-up {
            width: 80%;
        }
        .login {
            top: 60px;
            width: 283px;
            .username,
            .password,
            .Select {
                width: 215px;
            }
        }
    }
}

.sso{
    background-color: #5575a7;
    border: none;
    border-radius: 5px;
    padding: 5px;
    color: white;
}
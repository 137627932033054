@import '../../constants.scss';
$borderColor: #dadada;

@mixin commonInSeprator {
    text-align: center;
    height: 170px;
    cursor: pointer;
    padding: 0px;
    position: relative;
    display: inline-block;

    .number {
        margin: auto;
        padding: 20px;
        font-size: 70px;
        text-align: center;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .text {
        position: absolute;
        bottom: 15px;
        font-size: 16px;
        left: 0;
        width: 100%;
    }

    .icon {
        position: absolute;
        bottom: 15px;
        left: 30%;
        top: 22px;
    }

    .iconn {
        position: absolute;
        bottom: 15px;
        height: 58%;
        left: 30%;
        top: 22px;
    }

    &:hover {
        border: none;
        box-shadow: 0 0 60px rgba(0, 0, 0, 0.16), 0 6px 77px rgba(0, 0, 0, 0.1);
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    }
}

.homepage {
    padding: 20px;
    background-color: #003152;

    .links-container {
        padding: 25px 41px;
        color: white;
        .icon {
            width: 84px;
            height: 84px;
        }
        .menu, .checklist-menu {
            @include commonInSeprator();
            border-bottom: 0.52px solid $borderColor;
            border-right: 0.52px solid $borderColor;
        }
        .menu.col-sm-4:nth-child(3), .menu.col-sm-4:nth-child(6), .menu.col-sm-4:nth-child(9), .menu.col-sm-4:nth-child(12) {
            border-right: none;
        }
        .menu.col-sm-3:nth-child(4), .menu.col-sm-3:nth-child(8), .menu.col-sm-3:nth-child(12), .menu.col-sm-3:nth-child(16) {
            border-right: none;
        }
        /* .menu.col-sm-4:nth-child(7), .menu.col-sm-4:nth-child(8), .menu.col-sm-4:nth-child(9)  {
            border-bottom: none;
        }
        .menu.col-sm-3:nth-child(5), .menu.col-sm-3:nth-child(6), .menu.col-sm-3:nth-child(7), .menu.col-sm-3:nth-child(8) {
            border-bottom: none;
        } */
        .menu.col-sm-3:nth-child(9), .menu.col-sm-3:nth-child(10) {
            border-bottom: none;
        } 
        .top-row-middle {
            border-bottom: 0.52px solid $borderColor;
            border-right: 0.52px solid $borderColor;
            @include commonInSeprator();


            .icon {
                width: 84px;
                height: 84px;
            }

            .icon-3 {
                width: 76px;
            }

            color: white;
        }

        .top-row-right {
            border-bottom: 0.52px solid $borderColor;
            @include commonInSeprator();


            .icon {
                width: 74px;
                height: 82px
            }

            .icon-4 {
                width: 68px;
                height: 75px;
            }

            color: white;
        }

        .top-row-left {
            .icon {
                height: 74px;
                width: 88px;
            }

            border-bottom: 0.5px solid $borderColor;
            border-right: 0.52px solid $borderColor;
            color: white;
            @include commonInSeprator();
        }
        .middle-row-left {
            border-right: 0.52px solid $borderColor;
            @include commonInSeprator();

            .icon {
                width: 77px;
                height: 84px;
            }
            border-bottom: 0.5px solid $borderColor;
            color: white;
        }
        .middle-row-middle {
            .icon {
                width: 83px;
                height: 78px;
            }

            .icon-7 {
                width: 76px;
            }

            .icon-5 {
                height: 88px;
            }

            border-right: 0.52px solid $borderColor;
            border-bottom: 0.5px solid $borderColor;
            color: white;
            @include commonInSeprator();
        }
        .middle-row-right {
            .icon {
                width: 70px;
                height: 84px;
            }

            .icon-8 {
                width: 66px;
                height: 77px;
            }

            color: white;
            border-bottom: 0.5px solid $borderColor;
            @include commonInSeprator();
        }
        .bottom-row-left{
            .icon {
                height: 84px;
                width: 70px;
            }
            .icon-1 {
                height: 74px;
                width: 88px;
            }
            border-right: 0.52px solid $borderColor;
            color: white;
            @include commonInSeprator();
        }
        .bottom-row-middle{
            .icon {
                
                height: 84px;
                width: 70px;
            }
            border-right: 0.52px solid $borderColor;
            color: white;
            @include commonInSeprator();
        }
        .bottom-row-right{
            .icon {
                
                height: 84px;
                width: 70px;
            }
            color: white;
            @include commonInSeprator();
        }

    }

    .card-container {
        padding: 0px 20px 55px 20px;
        display: flex;
        justify-content: center;

        .col-sm-4 {
            .homepage-card {
                border: 1px solid white;
                background-color: #003152;

                &:hover {
                    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.50), 0 6px 16px rgba(0, 0, 0, 0.50);
                    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
                }

                cursor: pointer;
                padding: 0px;
                display: flex;

                .card-icon {
                    float: left;
                    width: 42%;
                }

                .card-details {
                    display: inline-block;
                    width: 55%;
                    text-align: center;

                    .card-label {
                        margin: 0;
                        line-height: 38px;
                        font-size: 14px;
                        color: white;
                    }

                    .card-data {
                        padding-top: 0px;
                        font-size: 20px;
                        margin: 0;
                        line-height: 10px;
                        color: white;
                    }
                }
            }
        }
    }
}

@mixin reportsLink {
    height: 70px;
    margin: 0px 0px 10px 0px;
    padding: 0px 7%; // width: 100%;
    border-right: none;
    border-left: none;
    border-bottom: 0.5px solid $borderColor;
    text-align: left;

    .number {
        padding: 0;
        display: inline-block;
        font-size: 40px;
        width: 35%;
        position: relative;
        top: 12px;
    }

    .text {
        position: unset;
        display: block;
        width: 60%;
        font-size: 16px;
        text-align: center;
        float: right;
        line-height: 25 px;
    }

    .icon {
        position: unset;
    }
}

@mixin noOfForms {
    padding: 0;
    font-size: 40px;
    display: block;
    margin: auto;
    width: 30%;
    text-align: left;
}

@mixin image {
    position: relative;
    right: 40px;
    bottom: 39px;
}

@mixin formLabel {
    position: relative;
    left: 30px;
    bottom: 36px;
}

@media screen and (min-height:568px) and (max-height:736px) {
    .homepage {
        .card-container {
            padding: 20px 44px;
        }

        .card-icon {
            width: unset;
        }
    }
}

@media screen and (max-width: 768px) {
    .homepage {
        padding: 20px;

        .links-container {
            padding: 15px 10%;

            .show-grid {
                padding-bottom: 0px;
                margin: auto;
            }

            .bottom-row-left {
                @include reportsLink();

                .text {
                    line-height: 25px;
                }

                .icon {
                    height: 58px;
                    width: 63px;
                }
            }

            .bottom-row-right {
                @include reportsLink();

                .text {
                    line-height: 25px;
                }

                .icon {
                    height: 58px;
                    width: 63px;
                }
            }

            .top-row-right {
                @include reportsLink();

                .icon {
                    height: 58px;
                    width: 63px;
                }
            }

            .top-row-left {
                @include reportsLink();

                .icon {
                    height: 50px;
                    width: 68px;
                }
            }

            .top-row-middle {
                @include reportsLink();

                .icon {
                    height: 58px;
                    width: 65px;
                }
            }

            .bottom-row-middle {
                @include reportsLink();

                .icon {
                    height: 58px;
                    width: 63px;
                }
            }
        }

        .card-container {
            flex-direction: column;

            .col-sm-4 {
                .homepage-card {
                    .card-icon {
                        width: unset;
                    }
                }
            }
        }
    }
}

@media (width: 1024px) {
    .homepage {
        .links-container {

            .bottom-row-left,
            .bottom-row-right,
            .bottom-row-middle,
            .top-row-left,
            .top-row-right,
            .top-row-middle {
                .icon {
                    height: 70px;
                    width: 75px;
                }
            }
        }
    }
}

@media (width: 768px) {
    .homepage {
        .links-container {

            .bottom-row-left,
            .bottom-row-right,
            .bottom-row-middle,
            .top-row-left,
            .top-row-right,
            .top-row-middle {
                height: 100px;
                border: none;
                margin-bottom: 0;
                text-align: center;

                .text {
                    width: 100%;
                    font-size: 14px;
                    text-align: center;
                }
            }

            .top-row-middle,
            .bottom-row-middle {
                border-left: 0.52px solid $borderColor;
                border-right: 0.52px solid $borderColor;
            }

            .bottom-row-left,
            .bottom-row-right,
            .bottom-row-middle {
                padding-top: 10px;
                padding: 10px;
                margin-bottom: 20px;
                border-top: 0.52px solid $borderColor;
            }
        }
    }
}

@media screen and (min-width:568px) and (max-width:736px) {
    .homepage {
        .card-container {
            flex-direction: column;

            .col-sm-4 {
                padding: 20px 100px;

                .homepage-card {
                    .card-icon {
                        width: unset;
                    }
                }
            }
        }
    }
}
.texas-home-page .links-container .col-sm-6 .icon {
    left: 40% !important;
}
.elms-tab{
    cursor: pointer;
    text-align: center;
    background-color: #dadada;
    width: 130px;
    height: 76px;
    border-radius: 94px;
    font-size: 36px;
    border: none;
    color: white;
    position: fixed;
    box-shadow: 3px 3px 3px 3px #26a65b;
    /* right: calc((100% - 1000px)/2 - -30px);
    right: calc(100% - 85vw); */
    right: 2%;
    top: 85.5%;
    z-index: 1;
    .elms-logo{
        width: 100px; 
        margin: 0;
        margin-top: 7.8%;
    }
    .dropdown-item:focus, .dropdown-item:hover {
        background-color: unset;
        color: #fff;
    }
}
@mixin shape-enable() {
  margin: 8px 10px;
  background-color: #a4bf43;
  border: none;
  height: 30px;
  padding: 0 20px;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
}

.map-button-container {
  height: auto;

  .map-container {
    width: 100%;
    height: 60vh;
  }

  .action-btns {
    /*  height: 10vh;*/
    text-align: -webkit-center;

    .shape-enable {
      @include shape-enable();
    }

    .shape-disable {
      margin: 8px 10px;
      background-color: rgb(161 96 96 / 40%);
      border: none;
      height: 30px;
      padding: 0 20px;
      font-size: 16px;
      border-radius: 5px;
      outline: none;
      color: white;
    }
  }

  .shape-inputs {
    text-align: center;
    color: black;

    textarea:focus,
    input:focus {
      outline: none;
    }

    .map-text-input {
      height: 35px;
      width: 40%;
      padding: 0;
      font-weight: bolder;
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      background-color: transparent;
      border: none;
      font-size: 17px;
      border-bottom: 2px solid #a4bf43;
      border-radius: 0;
      color: white;
      resize: none;
    }

    .map-text-save-button {
      @include shape-enable();
    }
  }
}

.cursor-tooltip {
  width: 150px;
  text-align: center;
  padding: 10px;
  padding-bottom: 0px;
  height: 85px;
  border-radius: 40px;
  background-color: rgb(231, 235, 238);
  opacity: 0.9;
  z-index: 999999999999;

  p :-moz-last-node{
    padding-bottom: 0px;
  }
}

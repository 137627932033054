@import '../../../generals.scss';
@import '../../../constants.scss';
@import '../../../mixins.scss';
$maxHeightForDetailsPrint:1000;

.print-preview-outer {
    margin: auto;
    border-radius: 5px;
    margin-bottom: 2%;
    /* height: auto; */
    word-wrap: break-word;
    height: 100%;
    overflow: auto;

    .filter-charts,.commonFilter {
        display: none;
    }

    .Select-control {
        border-radius: 17px;
    }

    .form-title {
        text-align: center;
        font-size: 30px;
        color: #88A4CE;

    }

    .steps {
        color: black !important;
    }

    .border {
        display: block;
        width: 80%;
        border-bottom: 1px solid black;
        margin: auto;
        padding-top: 5px;
    }

    .export-button-container {
        display: none;
    }

    .summary-reports {
        .container {
            .oi-report-card {
                margin: 20px;
            }

            .cone-labels {
                top: 20px;
            }
        }
    }

    .modal-dialog {
        padding: 0px;

        .modal-body {
            position: unset;
            padding: 15px;
            max-height: inherit;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            .modal-header {
                h4 {
                    text-align: center;
                    font-size: 30px;
                    color: #88A4CE;
                }
            }

            .print-preview {
                /* Added */
                color: #333;
                /* Added */
                margin: auto;
                padding: 0 10px;
                position: relative;
                padding: 0px;
                box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;

                .display-image-wrapper {
                    width: 150px !important;
                    pointer-events: none;
                }

                .que-detail {
                    color: #737373;
                }

                .action-label {
                    color: black;
                    font-weight: bold;
                }

                .pie {
                    pointer-events: none;
                }

                .associated-forms {
                    display: none;
                }

                .form-button {
                    display: none;
                }

                .checkbox-field {
                    margin: 0;
                }

                .checkbox-label {
                    position: relative;
                    bottom: 5px;
                    padding: 0 5px;
                }


                @include checkBox();

                .print-body {
                    position: relative;
                    transform: scale(0.9);
                    bottom: 45px;
                }

                .oi-report-summary,
                .incident-report-summary {
                    padding: 0px;
                    .summary-reports {
                        transform: scale(0.9) translateX(-50%);
                        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
                        padding: 20px 10px;
                    }

                    min-height: 590px;
                }

                .incident-report-summary, .summary-reports {
                    min-height: 540px;
                     /* Added */
                    .oi-report-card {border: 1px solid rgba(0, 0, 0, 0.12) !important; }
                     /* Added */
                }

                /* .oi-report-summary,
                .incident-report-summary {
                    padding: 0px;

                    .summary-reports {
                        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
                        padding: 20px 10px;
                    }
                } */

                .print-filter-wrapper {
                    .filter-title {
                        line-height: 60px;
                        font-size: $headingFontSize;
                        color: yellow;
                    }

                    .print-data-filter {
                        width: 100%;

                        .chart-filter {
                            height: 60px;
                            padding: 20px;
                            color: white;
                        }
                    }
                }

                .report-chart {
                    width: 100%;

                    .legends {
                        position: absolute;
                        right: 0;
                    }
                }

                .report-card {
                    text-align: center;
                    width: inherit;
                }

                .card-one {
                    width: 50%;
                }

                .total-reports {
                    width: 100%;
                }
            }
        }
    }

    .circle {
        width: 56%;
        height: 253px;
        display: inline-block;
    }

    .chart {
        position: relative;
    }

    .oi-report-summary {
        /* Added */
        .show-grid h4{color: #333 !important;}
        .oi-report-card, .office-safety-report-card{border: 1px solid rgba(0, 0, 0, 0.12); }
        /* Added */
        .observation-count {
            height: 50vh;
        }

        .legends {
            position: absolute;
            width: 44%;
            top: 35px;
            height: 50vh;
            display: inline-block;
            padding: 0 !important;
            text-align: left;

            .legend {
                display: block;
                text-align: left;
                margin: 0; 
            }
        }
    }

    .legends {
        position: absolute;
        width: 44%;
        top: 35px;
        height: 253px;
        display: inline-block;
        padding: 33px !important;
        text-align: left;

        .legend {
            display: block;
            text-align: left;
            margin: 0;
            /* Added */
            height: 20px !important; 
            p{height: 15px !important;}
            .legend-text{font-size: 12px !important;}
            /* Added */
        }
    }

    .print-button {
        text-align: center;
        width: 100%;
        padding: 20px;

        .button-disabled {
            width: 176px;
            color: white;
            margin: 0px 20px;
            border: none;
            background-color: #26a65b;
            font-size: 18px;
            height: 40px;
            border-radius: 17\px;
            outline: none;
            cursor: not-allowed;
        }

        .button {
            &:hover {
                cursor: pointer;
            }
        }
    }
}
@media screen and (max-width:767px) {
    .print-preview-outer {
        .modal-dialog {
            .modal-content {
                .print-button {
                    bottom: 20px;
                }
            }

            .modal-body {
                .print-preview {
                    position: relative;
                    transform-origin: left;

                    .report-details,
                    .summary-reports {
                        width: 597px;
                        padding: 20px 40px;
                        transform-origin: top left;
                        left: 50%;
                        position: relative;
                        transform: scale(0.6) translateX(-50%);

                        .step-row {
                            width: 33% !important;
                            display: inline-block !important;
                            text-align: center !important;
                        }
                    }

                    .oi-report-summary {
                        max-height: 920px;
                    }

                    .incident-report-summary {
                        max-height: 950px;
                    }

                    .oi-report-summary,
                    .incident-report-summary {
                        .summary-reports {
                            transform: scale(0.6) translateX(-50%);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (orientation:landscape) {
    .print-preview-outer {
        .modal-dialog {
            .modal-content {
                overflow: hidden;
                height: auto;

                .print-button {
                    bottom: 20px;
                }
            }

            .oi-report {
                max-height: 100%;
            }

            .incident-report {
                max-height: 100%;
            }

            .modal-body {
                .oi-report {
                    max-height: 100%;
                }

                .incident-report {
                    max-height: 100%;
                    ;
                }

                .print-preview {
                    width: 100%;
                    transform: unset;

                    .report-details,
                    .summary-reports {
                        width: 597px;
                        transform-origin: top left;
                        left: 50%;
                        position: relative;
                        margin: 0;
                        padding: 0 15px;
                        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
                        padding: 20px 10px;
                        transform: scale(0.9) translateX(-50%);

                        .steps {
                            line-height: 2;

                            .step-row {
                                width: 33%;
                                display: inline-block;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (width: 320px) {
    .print-preview-outer {
        .modal-dialog {
            .modal-body {
                .print-preview {
                    .report-details {
                        transform: scale(0.5) translateX(-50%);
                    }

                    .print-filter-wrapper {
                        .print-data-filter {
                            .chart-filter {
                                padding: 0px;
                            }
                        }
                    }

                    .oi-report-summary {
                        max-height: 865px !important;
                    }
                }
            }
        }
    }
}

@media screen and (width:568px) {
    .print-preview-outer {
        .modal-dialog {
            .modal-content {
                .print-button {
                    bottom: 20px;
                }
            }

            .modal-body {
                .print-preview {
                    .oi-report-summary {
                        max-height: 920px;
                    }

                    .incident-report-summary {
                        max-height: 950px;
                    }

                    transform: unset;
                }
            }
        }
    }
}

@mixin adjustForIpad {
    .print-preview-outer {
        .modal-dialog {
            .modal-body {
                .print-preview {

                    .report-details,
                    .oi-report-summary,
                    .incident-report-summary {
                        .summary-reports {
                            transform: scale(1);

                            .step-row {
                                width: 33%;
                                display: inline-block;
                                text-align: center;

                                .col-sm-8 {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (width: 768px) {
    @include adjustForIpad();
}

@media only screen and (width: 1024px) {
    @include adjustForIpad();
}

/* .summary-reports */#print{
    border: 1px solid #808080;
}   
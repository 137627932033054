@import '../../constants.scss';
.hp-checklist{
    margin-top: 20px;
    padding: 10px;
    color: yellow;
    .heading{
        font-size: 18px;
    }
    .checklists {
        padding-top: 20px !important;
        .sno{
            text-align: center !important;
        }
        .checklists{ 
            color: yellow; 
        }
        .radio-options{
            .fa {
                font-size: 35px;  
            }
            .fa.fa-smile-o{
                color: #10c910;
            }
            .fa.fa-frown-o{
                color: red;
            }
        }
        .options{
            text-align: center !important;
        } 
    }
    .overallscorelabel {
        font-size: 18px;
        text-align: right !important;
    }
    .overallscore{
        text-align: center !important;
    }
    .radio-input:not(:checked) + label:before{
        display: none !important;
    }
    .radio-input:checked + label:before {
        display: none !important;
    }
    input:checked + label:after,  input:not(:checked) + label:before { 
        display: none !important;
    } 
    .radio-input:not(:checked) + label {
        padding-left: unset !important;
    }
    .radio-option{
        padding-left: 3px !important;
        padding-right: 3px !important;
        border-radius: 10px;
    }
    input:checked + label.radio-option.green {
        background-color: #c0ffb0;  
    }
    input:checked + label.radio-option.red {
        background-color: #efcccc;  
    }
    .checklist-result{
        padding-left: 3px;
        border-radius: 10px;
        padding-right: 3px;
        font-size: 35px; 
    }
    .checklist-result.green {
        color: #10c910;
        background-color: #c0ffb0; 
    }
    .checklist-result.red {
        color: red;
        background-color: #efcccc; 
    }
}
.hp-report{
    .checklist-result{
        padding-left: 3px;
        border-radius: 10px;
        padding-right: 3px;
        font-size: 35px; 
    }
    .checklist-result.green {
        color: #10c910;
        background-color: #c0ffb0; 
    }
    .checklist-result.red {
        color: red;
        background-color: #efcccc; 
    }
}
.hp-summary{
    .sad-details-chart{
        margin-top: 20px;
    }
    .checklist-result.green {
        color: #10c910; 
    }
    .checklist-result.red {
        color: #FF0000; 
    }
}
.hp-checklist.details{
    margin-top: unset !important;
    .checklists {
        padding-top: unset !important;
        color: #fff !important;
        margin: unset !important;
    }
}
.preExcavationChecklist, .utilityDamageReport{
  .row {
    margin-top: 15px;
  }
  .text-left{
    text-align: left !important;
    margin-bottom: 1rem;
  }

  .checkbox-label {
    color: white;
  }

  .radioInput {
    text-align: left !important;
  }

  hr {
    border: 2px solid white;
  }

  .row-cell-height {
    height: auto;
  }

  .question-title {
    color: white;
    position: relative;
    margin-left: 30px;
  }

  .advise-title {
    color: white;
  }

  .width-height-100-textcenter {
    width: 100%;
    text-align: center;
    padding-top: 5px;
    height: 100%;
  }

  .top-info {
    border: 1px solid white;
    border-radius: 15px;
    padding-top: 10px;
    color: white;

    .centered-text {
      text-align: center;
    }
  }

  .header-container {
    display: flex;
    place-content: center;

    .lang-select {
      width: 120px;
      margin-left: 10px;
      color: black !important;

      div[class*="control"] {
        border-radius: 40px;
      }
    }
  }

  .key-data {
    color: yellow;
    margin-top: 15px;

    span {
      color: white
    }
  }

  .office-safety-report .show-grid {
    padding-top: 0;
    padding-bottom: 0;
  }

  .yellow-color-title {
    color: yellow
  }

  #print {

    .lang-select {
      display: none;
    }

    .yellow-color-title {
      color: black;
      font-weight: 600;
    }

    .top-info {
      color: black;
    }

    .key-data {
      color: black;
      font-weight: bolder !important;
      margin-top: 15px;

      span {
        color: black;
        font-weight: normal;
      }
    }

    .question-title{
      color: black;
      font-weight: 500;
    }

    .checkbox-label {
      color: black;
      font-weight: 700 !important;

      span {
        font-weight: 100 !important;
      }
    }

    .advise-title, .attachement {
      color: black;
    }

    ul {
      color: black;
    }
    .map-button-container{ display: none;}
  } 
}
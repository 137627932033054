.add-user,
.update-profile {
    padding: 20px;
    .show-grid {
        padding-bottom: 20px;
        .form-control {
            border-radius: 17px;
        }
    }
    
}
.m-signature-pad .m-signature-pad--footer .button.clear {
    background-color: white;
    color: black;
}


.m-signature-pad--body-- {
   /*  width: 290px;  
    background-color: white;
    border-radius: 10px;*/
    /* canvas{
        height: 56px !important; 
        width: 275px !important;
        margin-top: 7px;
        border: solid 1px black;
    } */
} 
/* .m-signature .btn {
    margin-top: 10px;
}  */
/* .m-signature-pad--footer{padding-left: 18%; width: 290px;}  */

/* .signature-generator {
    .signature-container{ 
        width: 290px;
        height: 80px !important;
        border-radius: 10px;
    }
    .footer-container {
        width: 290px;
        margin-top: 2%;
        .Select {
            width: 60%;
        }
    }
} */
// @import '../styles/common.scss';
.pagination-container {
    display: flex; // background-color: white;
    padding: 20px;
    justify-content: center;
    margin: 0 -35px; // box-shadow: 0 4px 5px rgba(0, 0, 0, 0.45);
    .page-number {
        // color: $greyHeaderText;
        color: #333;
        background-color: white;
        font-size: 16px;
        margin-left: 10px;
        cursor: pointer;
        padding: 5px;
        min-width: 33px;
        border-radius: 50%;
        text-align: center;
        border: 1px solid #ded9d9;
    }
}

@mixin divMixin() {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

@mixin iMixin() {
    border: solid #4d4cc3;
    border-width: 0px 3px 3px 0px;
    display: inline-block;
    padding: 14px;
    height: 30px;
    position: absolute;
    width: 30px;
}

.bar-container {
    display: flex;
    justify-content: space-between;
    .start {
        @include divMixin();
        margin-right: 20px;
        .left {
            @include iMixin();
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
        }
    }
    .end {
        @include divMixin();
        margin-left: 20px;
        .right {
            @include iMixin();
            right: -13px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }
    }
    &:hover {
        cursor: pointer;
    }
}
.border-top {
    border-top: solid 1px silver;
}

.table-label {
    color: yellow;
    padding-top: 5px;
    display: inline-block;

}

.text-center {
    text-align: center;
}

.office-safety-report {
    .page-title {
        margin-bottom: 20px;
    }

    margin: auto;
    padding: 20px 20px;

    .show-grid {
        padding-bottom: 20px;

        .checkbox-input {
            padding-top: 5.5px;
        }
    }

    .location-browser {
        border: 1px solid #cccccc;
        line-height: 30px;
        border-radius: 17px;
        padding: 0px 0px;
        margin-left: 15px;
        width: 45.5%;
    }
}

.office-safety-form {
    background-color: #003152;

    .show-grid {
        padding-bottom: 20px;
    }

    .radio-label {
        display: flex !important;
    }

    textarea {
        resize: none;
    }

    Label {
        color: yellow;
    }

    #action {
        color: yellow;
    }
}

.office-safety-summary {
    padding: 20px;

    .reports-data {
        text-align: center;
        color: gray;
    }

    text {
        font-size: 11px;
    }
}
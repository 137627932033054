@import '../../JSAForm/jsaForm.scss';
.golden-rules {
    padding: 20px;
    min-height:30vh;
    .rules-list{
        color: white;
    }
    @include tableInput();
    .close{
        color:white;
    }
    .modal-title{
        color:yellow;
    }
    .table-buttons {
        border: none;
    }
    .rules-form{
        .show-grid{
            margin: 0;
        }
    }
    .fa-trash-o {
        font-size: 20px;
        color: white;
        text-align: right;
        line-height: 2.5;
        &:hover {
            cursor: pointer;
        }
    }
    .danger-alert {
        height: 20px;
        line-height: 1.5;
    }
    .success-alert {
        height: 20px;
        p {
            position: relative;
            bottom: 10px;
        }
    }
}

@media screen and (orientation:portrait) {
    .golden-rules {
        .rules-form {
            max-height: 200px;
            .show-grid {
                margin: 0;
            }
        }
    }
}
@import '../../constants.scss';
@import '../OIForm/oiForm.scss';
@mixin coordsStyle {
    border: 1px solid #cccccc;
    line-height: 34px;
    border-radius: 17px;
    padding: 0px 10px;
}

.incident-notification-list {
    @include listPageHeadingAdjustment();
}

.incident-notification,
.incident-report {
    margin: auto;
    padding: 20px;
    Label{
        color:yellow;
    }
    legend{
        color:yellow;
    }
    .page-title {
        margin-bottom: 20px;
    }
    .follow-up {
        padding-top: 5.5px;
        display: inline;
        position: absolute;
    }
    .show-grid {
        padding-bottom: 20px;
    }
    .location-browser {
        border: 1px solid #cccccc;
        line-height: 30px;
        border-radius: 17px;
        padding: 0px 0px;
        margin-left: 15px;
    }
    .export-button-container {
        width: 50%;
        float: left;
        text-align: right;
    }
    textarea {
        resize: none;
    }
}

.incident-report-summary {
    .report-chart {
        padding: 20px;
    }
}

@media screen and (max-width: 666px) {
    @include filterSmallDevices();
    .incident-notification {
        padding: 20px;
        .show-grid {
            padding-bottom: 5px;
        }
    }
    .report-row {
        .col-sm-1 {
            display: none;
        }
    }
}

@media screen and (min-width:667px) and (max-width: 767px) {
    @include filterSmallDevices();
    .report-row {
        .col-sm-1 {
            display: none;
        }
    }
    .incident-report {
        .latitude,
        .longitude {
            @include coordsStyle();
        }
    }
}

@media screen and (width: 320px) {
    @include filterSmallDevices();
}

.incident-report {
    .latitude,
    .longitude {
        @include coordsStyle();
    }
}
@mixin alert {
    display: block;
    text-align: center;
    line-height: 35px;
    height: 35px;
}

.danger-alert {
    @include alert();
    color: red;
}

.success-alert {
    @include alert();
    color: green;
}

@media screen and (max-width: 768px) {
    .danger-alert {
        @include alert();
        line-height: 36px;
        height: 20px;
    }
}
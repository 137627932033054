    .profile {
        width: 100% !important;
        height: 100%;
        margin: auto;
        background-color: #003152;
        color:white;
        border-radius: 5px;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        .Select-value,
        .Select-placeholder
        {
            right: unset !important;
        }
        .Select-menu-outer {
            text-align: left !important;
            height: 50px !important;
            overflow: scroll;
        }
        .user-data {
            .show-grid {
                padding-bottom: 20px;
            }
        }
        .update-profile {
            .show-grid {
                padding-bottom: 20px;
            }
        }
        .key {
            text-align: right;
            color: yellow;
        }
        .value {
            text-align: left;
        }
        .user-data {
            align-self: center;
            padding: 34px;
            border: 1px solid #ffffff;
            margin: 20px;
            border-radius: 5px;
        }
    }

    .table-responsive {
        background-color: white;
    }

    @media screen and (max-width: 768px) {
        .profile {
            height: 100%;
            margin: 25px 0px 0px 0px;
            padding: 0px 0px;
            .key {
                text-align: left;
            }
            .user-data {
                padding: 0px;
                border: none;
            }
            .update-profile {
                .show-grid {
                    padding-bottom: 5px;
                    text-align: left;
                }
            }
        }
    }

    @media screen and (width: 736px) {
        .profile {
            .key {
                text-align: left;
            }
        }
    }
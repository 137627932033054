.tbm-form{
    .legend-header{
        color: #333 !important;
        background-color: #ffff71;
        width: 96% !important;
        padding: 4px;
        text-align: center;
        border-radius: 10px;
    }
    .col-12, .col-sm-4{
        margin-top: 20px;
    }
    .color-red{
        color: red;
    } 
    .table-buttons{
        text-align: center;
        margin-top: 15px;
        width: 100%;
        border-bottom: 1px solid #4b77be;
        padding-bottom: 20px;
        border: none;
        .add-row{
            width: 20%;
            background-color: #4b77be;
            border: none;
            border-radius: 17px;
            color: white;
            height: 34px;
        }
        .add-row.w35{
            width: 35%;
        }
        .add-row.w25{
            width: 25%;
        }
    }
    .singleLine{ display: flex;
        .contentSingleLine{
            display: inline-block;
            margin-left: 50px;
            padding-top: 0px;
            margin-top: 10px !important;
        }
    } 
    .yellow-color-title{
        color: yellow;
    }
    .checkbox-label{ color: white !important;}
    .text-left{text-align: left !important;}
    .radioInput{margin-top: 10px;}

}
.report-details {
    .col-12{
        margin-top: 10px;
    }
    .checkbox-label{
        text-align: left !important;
    }
    .key-value { 
        text-align: left !important;
        padding: 0 5px;
        color: white !important;
        label{ 
            color: white !important;
        }
    }
    .color-red{
        color: red;
    } 
   
}
.report-details#print{
    color: gray !important;
    .legend-header{color: #333;}
    .key-data, .key-data label{color: gray !important;}
    .key-value,.key-value label {color: black !important;}
    .color-red{
        color: red;
    } 
}
.risk-assessment {
    .parant-asessment{
        button{
            float: left;
            background-color: green;
            color: #fff;
            border-radius: 5px;
            border-color: green;
            margin-bottom: 5px;
            
        }
    }
    .assessment-hint{ 
        .card-header{
            background-color: #dcdcdc;
            button {
                color: rgb(84, 121, 175);
                border: 1px solid #5479AF;
                background-color: white;
                float: right;
            }
        }
        .card-body{
            .event-categorization {
                th{
                    text-align: center;
                    font-style: italic;
                }
                td:nth-child(1) { text-align: center; font-weight: 600;}
            } 
        }
    }
    .probability-hint{ 
        .card-header{
            background-color: #dcdcdc;
            button {
                color: rgb(84, 121, 175);
                border: 1px solid #5479AF;
                background-color: white;
                float: right;
            }
        }
        .card-body{ 
            .probability-categories{
                th{
                    text-align: center;
                    font-style: italic;
                }
                td:nth-child(1) { text-align: center; font-weight: 600;}
            }
        }
    }
    .event-assessment-content{
        margin-top: 15px;
        margin-bottom: 30px;
    }
    .trash{
        color:grey;
        cursor: pointer;
    }
    .step-no{
        color: #fff;
    }
    .table-buttons {
        text-align: center;
        margin-top: 15px;
        width: 100%;
        border-bottom: 1px solid #4b77be;
        padding-bottom: 20px;

        .add-row {
            width: 66%;
            background-color: #4b77be;
            border: none;
            border-radius: 17px;
            color: white;
            height: 34px;
        }
    }
    .analysis-result-content {
        margin-bottom: 20px;
        .analysis-result{
            text-align: center;
            /* margin-bottom: 20px; */
        }
        .result-label{
            color: white;
            font-size: 20px;
            cursor: default;
        }
        
        .analysis-result-hint{ 
            margin-bottom: 20px; margin-top: 20px; 
            .card-header{
                background-color: #dcdcdc;
                button {
                    color: rgb(84, 121, 175);
                    border: 1px solid #5479AF;
                    background-color: white;
                    float: right;
                }
            }
            .card-body{
                .event-categorization {
                    th{
                        text-align: center;
                        font-style: italic;
                    }
                    td:nth-child(1) { text-align: center; font-weight: 600;}
                } 
            }
        }
        .analysis-result-table-content{

            .table-responsive{margin: 0 auto;width: 60%}
            text-align: center;
            .analysis-result-table{ 
                th:nth-child(1) { 
                    width: 55%;
                }
                th:not(:first-child), td:not(:first-child)  {
                    text-align: center;
                }
                /* .highlight{
                    border: 3px #040101a3 solid;
                    font-size: 22px;
                    font-weight: 600;
                    background-image: repeating-linear-gradient( 
                        45deg, transparent, transparent 17px, #ccc 10px, #ccc 20px ), repeating-linear-gradient( 
                        135deg, transparent, transparent 25px, #ccc 10px, #ccc 20px );
                } */
                .highlight{  
                    font-size: 22px;
                    font-weight: 600;
                }
                .res1.highlight{
                    background: linear-gradient(#f00202 0%, #bd0404 10%, #7d0404 50%, #4a0202 100%);
                    border-radius: 50%;
                }
                .res2.highlight{
                    background: linear-gradient(#feff00 0%, #feff00 10%, #b0b10b 50%, #7a7a00 100%);
                    border-radius: 50%;
                }
                .res3.highlight{
                    background: linear-gradient(#479547 0%, #479547 10%, #479547 50%, #055205 100%);
                    border-radius: 50%;
                }
                .res4.highlight{
                    background: linear-gradient(#006300 0%, #006300 10%, #0e440e 50%, #144c14 100%);
                    border-radius: 50%;
                }
                .risk-severity-categories th{width: 25% !important;}
            }
        }
    }
    .result-no{
        /* color: white;
        background-color: #c72525; */
        padding: 3px 25px 3px 25px;
        border-radius: 10px;
        cursor: default;
        font-weight: 600;
    }
    .res1{
        color: #fff;
        background-color: brown;
        
    }
    .res2{
        /* color: #fff;
        background-color: #45459e;  */
        color: #000;
        background-color: #ffff00;
    }
    .res3{
        color: #fff;
        background-color: #479647; 
    }
    .res4{
        color: #fff;
        background-color: darkgreen; 
    }
    .followup-content{ 
        .card-header{
            background-color: #dcdcdc;
            button {
                color: rgb(84, 121, 175);
                border: 1px solid #5479AF;
                background-color: white;
                float: right;
            }
           
        }
        .card-body{
            background-color: #013151;
            .list-page{
                min-height: unset !important;
                .serial-no{
                    
                }
            }
        }
        .followup-button{
            text-align: center;
            margin-top: 3px;
        }
        .followup-add{ 
            margin-right: 20px;
            color: white;
            border-radius: 2px;    border-color: green;
            background-color: green;
        }
    }
    .followup-risk-assessment-content{
        margin-top: 20px;
        margin-bottom: 20px;
        .card-header{
            background-color: #dcdcdc;
            button {
                color: rgb(84, 121, 175);
                border: 1px solid #5479AF;
                background-color: white;
                float: right;
            }
        }
        .card-body{
            background-color: #013151;
            .list-page{
                min-height: unset !important;
                .serial-no{
                    
                }
            }
            .followup-button{
                text-align: center;
                margin-top: 3px;
            }
            .risk-assessment-followup-add{
                margin-right: 20px;
                color: white;
                border-radius: 2px;    border-color: green;
                background-color: green;
            }
        }
    }
    .border{border: unset !important;
        border-bottom: 1px solid white !important;}
} 
#eventScoringHelp{
    /* right: 0px !important; */
    /* margin-left: 250px !important; */
}
.checkbox-row {
    margin: 0 0px 20px 0 !important;
}
.m-signature-pad--footer {
    width: unset !important;
}
.approval-signature{
    .display-image-wrapper{
        width: 190px;
        height: 75px;
        border-radius: 7px;
        background-color: white;
        img{
            width: 190px;
            height: 75px;
        }
    }
}
#print { 
    .assessment-details {
        color: black;
    }
    .analysis-result{
        color: black;
    }
    .color-gray{
        color: #88A4CE !important;
    }
    .color-black{
        color: black !important;
    }
    .avg-score{
        padding: 3px 15px 3px 15px !important
    }
    .approval-signature .col-sm-4{
        width: 50%
    }
    .m-20{margin-top: -20px !important;}
    .analysis-result-table-content{
        margin: 0px !important;
    }
    .analysis-result-table-content .table-responsive{ text-align: center !important;
        width: 85% !important;
    }
}
#print { 
    .card50{
        width: 50%;
    }
    
}
.print-preview-outer .oi-report-summary .legends .legend {
    overflow: hidden;
}
.ssriResultHint {
    .table-responsive {
        border-radius: 10px;
    }
    table {
        margin-top: 15px;
    }
}
.upload-image{
    color: green !important;
}
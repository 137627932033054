@mixin checkBoxMixin {
    position: absolute;
    background: $submitButtonColor;
    width: 15px;
    height: 15px;
    top: 5px;
    left: 5px;
    border-radius: $hundredPercent;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

@mixin checkBox {
    .checkbox-field {
        margin: 0;
    }
    .checkbox-label {
        position: relative;
        bottom: 5px;
        padding: 0 5px;
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 44px;
        height: 23px;
        margin: 0px;
    }
    .switch input {
        display: none;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }
    .slider:before {
        position: absolute;
        content: "";
        height: 19px;
        width: 20px;
        left: 2px;
        top: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
    input:checked+.slider {
        background-color: $submitButtonColor;
    }
    input:focus+.slider {
        box-shadow: 0 0 1px #2196F3;
    }
    input:checked+.slider:before {
        -webkit-transform: translateX(19px);
        -ms-transform: translateX(19px);
        transform: translateX(19px);
    }
    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }
    .slider.round:before {
        border-radius: 50%;
    }
    .regular-checkbox {
        display: none;
        +label {
            background-color: #fafafa;
            border: 1px solid #cacece;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            padding: 9px;
            border-radius: 3px;
            display: inline-block;
            position: relative;
            }
        &:disabled+label {
            cursor: not-allowed;
        }
    }
    .radio-input {
        &:checked,
        &:not(:checked) {
            display: none;
        }
        &:checked+label,
        &:not(:checked)+label {
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            line-height: 20px;
            display: inline-block;
            margin: 0px 13px -5px 0px;
            top: -12px;
        }
        &:checked+label:before,
        &:not(:checked)+label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 25px;
            height: 25px;
            border: 1px solid #ddd;
            border-radius: $hundredPercent;
            background: #fff;
        }
        &:checked+label:after {
            content: '';
            @include checkBoxMixin();
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }
        &:not(:checked)+label:after {
            content: '';
            @include checkBoxMixin();
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
        }
        &:checked+label:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
}

// @mixin wideReportPageButtons() {
//     .oi-list-wrapper,
//     .incident-list-wrapper,
//     .investigative-list-wrapper,
//     .jsa-list-wrapper {
//         .add-new-report {
//             background-color: $submitButtonColor;
//             @include listPageButtons();
//             right: calc(100% - 85vw);
//             top: 89%;
//         }
//         .summary-page {
//             background-color: $summaryButton;
//             @include listPageButtons();
//             right: calc(100% - 85vw);
//             top: 81.5%;
//             z-index: 1;
//             .fa {
//                 font-size: 21px;
//                 position: relative;
//                 bottom: 5px;
//                 color: black;
//             }
//         }
//     }
// }
.concern-type-form{
    .formLabel{
        text-align: right !important;
    }
    .activites-title{
        color: #fff; 
        text-align: center;
    }
    .table-buttons {
        text-align: center;
        .add-row {
            width: 40%;
            background-color: #4b77be;
            border: none;
            border-radius: 17px;
            color: white;
            height: 34px;
        }
    }
    .table-actions{
        .delete-concern-type{
            .fa-trash-o {
                font-size: 20px;
                color: white;
                text-align: right;
                line-height: 2.5;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .add-row{ 
            background-color: #4b77be;
            border: none;
            border-radius: 17px;
            color: white; 
            font-size: 12px;
            margin-left: 10px;
        }
    }
} 
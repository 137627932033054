$textLineHeight: 30px;
$titleColor: #5479AF;
$fontColor: yellow;
$trHoverColor: #A1BAE1;
$fontSizeSmall: 12px;
$textSize:14px;
$buttonFontSizeSmall:13px;
$fontSizeMedium: 20px;
//$basepageWidth: 665px;
$basepageWidth: 1000px;
$submitButtonColor:#26a65b;
$detailsPageKeyColor:yellow;
$reactSelectHoverColor:#d8eafd;
$hundredPercent: 100%;
$hundred: 100;
$headingFontSize:18px;
$buttonWidthLarge:190px;
$inputBoxBorderColor:#66afe9; 

$backgroundColor: #003152;
$menuActive: #06408ccc; //rgba(0, 49, 113, 0.8);
$menuActiveBorderBottom: #f3b338;
$defaultButtonColor: #083b72; 

/* $fontColor: #ffff7d; */
$formTitleColor: #f8f841;
$formFontColor: #ffff7d;
@import '../OIForm/oiForm.scss';

@mixin tableInput {
    .table-buttons {
        text-align: center;
        margin-top: 15px;
        width: 100%;
        border-bottom: 1px solid #4b77be;
        padding-bottom: 20px;

        .add-row {
            width: 66%;
            background-color: #4b77be;
            border: none;
            border-radius: 17px;
            color: white;
            height: 34px;
        }
    }
}

.yellow-center {
    color: yellow;
    text-align: center;
    font-size: 16px !important;
}

@mixin trash {
    .trash {
        position: absolute;
        bottom: 0;
        height: 5px;
        margin-top: -4px;
        width: 100%;

        .fa-trash {
            color: white;
            width: 94%;
            margin-top: 3px;
            background-color: #cbc7c7;
            height: 33px;
            font-size: 22px;
            line-height: 32px;
            margin-left: -5px;
            border-bottom-left-radius: 17px;
            border-bottom-right-radius: 17px;
        }
    }
}

@mixin textAreaInput {
    .textarea-input {
        padding: 0px;

        .step-description {
            border-bottom: none;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        .hazards-risk {
            border-radius: 0px;
        }

        .controls-trash {
            border-top: none;
            border-radius: 0px;
        }

        .controls {
            border-top: none;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }
    }
}

@mixin detailsPageStepRows {
    .steps {
        padding: 10px 0px;

        .step-row {
            padding: 3px 0px;
            width: 80%;
            margin: auto;
        }
    }
}

@mixin detailsPageStepRowsSmallDevices {
    .steps {
        .step-row {
            width: 100%;
            margin: auto;
        }
    }
}

.jsa-report {
    padding: 20px;

    .page-title {
        margin-bottom: 20px;
    }

    .show-grid {
        width: 100%;
        margin: auto;
        padding-bottom: 20px;
    }

    .job-safety-steps {
        padding: 10px 0px;

        .step-row {
            padding: 3px 0px;
            width: 80%;
            margin: auto;
        }
    }

    .table {
        .table-rows {
            .col-sm-8 {
                display: none;
            }

            .steps {
                padding: 0px;
                line-height: 1;

                .step-row {
                    width: 33%;
                    display: inline-flex;
                    text-align: center; color: #fff;

                    .col-xs-8 {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.jsa-form {
    padding: 20px;
    /* 
        Removed Scroll and overflow
    overflow: auto;
    height: 750px; */

    .show-grid {
        padding-bottom: 20px;
    }

    .signature {
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .jsa-signature-generator {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    /* .m-signature-pad--footer {
        padding-left: 30%;
        float: left;

        .btn-default {
            width: 85px !important;
        }
    } */

    .jsa-table {
        text-align: center;
        color: #88A4CE;
        border-bottom: 1px solid;
        padding: 5px;
        margin: auto;
    }

    .trash {
        width: 20px;
        color: grey;
        right: 10px;
    }

    .step-no {
        right: 12px;
        color: white;
    }

    .textarea-header {
        padding: 2px;
        width: 27%;
        margin: 0 3%;
    }

    .table-responsive {
        margin: auto;
        width: 90%;
    }

    .input-fields {
        padding: 5px;
        width: 100%;
        margin: auto;

        .textarea-input {
            padding: 0;
            width: 30.1%;
            margin: 0px 9px;

            textarea {
                resize: none;
                border-radius: 10px;
            }
        }
    }

    @include tableInput();
}

.jsa-form-list {
    padding: 20px;
    min-height: 84vh;
}

.jsa-form {
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: #A9A9A9;
        box-shadow: #A9A9A9;
        border-radius: 10px;
        background-color: white;
    }

    &::-webkit-scrollbar {
        width: 10px;
        background-color: white;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: lightgrey;
        box-shadow: lightgrey;
        background-color: lightgrey;
    }
}

@mixin jsaSafetySteps {
    .jsa-report {
        .table {
            .col-sm-4 {
                display: none;
            }

            .table-rows {
                margin: 0px;

                .col-sm-8 {
                    display: block;
                }

                .steps {
                    padding: 10px 0px;
                    line-height: 2;

                    .step-row {
                        width: 100%;
                        display: block;
                        text-align: left;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:768px) {
    @include jsaSafetySteps();
    @include detailsPageStepRowsSmallDevices();

    .jsa-form {
        .show-grid {
            padding-bottom: 5px;
        }

        .input-fields {
            position: relative;
            margin-bottom: 50px;
            padding: 0;

            .textarea-input {
                width: 100%;
                padding: 0px;
                margin: 0;

                .step-description {
                    border-bottom: none;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                }

                .hazards-risk {
                    border-radius: 0px;
                }

                .controls-trash {
                    border-top: none;
                    border-radius: 0px;
                }

                .controls {
                    border-top: none;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                }
            }
        }

        .jsa-table {
            display: none;
        }

        .trash {
            position: absolute;
            bottom: 0;
            padding: 0px !important;
            left: 0px;
            height: 5px;
            margin-top: -4px;
            width: 100%;

            .fa-trash {
                color: white;
                width: 100%;
                margin-top: 5px;
                background-color: #cbc7c7;
                height: 33px;
                font-size: 22px;
                line-height: 32px;
                border-bottom-left-radius: 17px;
                border-bottom-right-radius: 17px;
            }
        }

        .step-no {
            margin: 0% -3%;
        }

        .table-buttons {
            margin-top: 30px;
        }
    }
}

@media screen and (max-width: 666px) {
    @include jsaSafetySteps();
    @include detailsPageStepRowsSmallDevices();

    .jsa-form {
        .show-grid {
            padding-bottom: 5px;
        }
    }

    .jsa-form-list {
        .report-row {
            .col-sm-1 {
                display: none;
            }
        }
    }

    .checkbox-input {
        display: inline-block;
    }

    .latitude,
    .longitude {
        @include coordsStyle();
    }
}

@media screen and (min-width:667px) and (max-width: 768px) {
    @include jsaSafetySteps();
    @include detailsPageStepRowsSmallDevices();

    .jsa-form {
        padding: 0px;

        .show-grid {
            padding-bottom: 5px;
        }
    }

    .jsa-form-list {
        .report-row {
            .col-sm-1 {
                display: none;
            }
        }
    }

    .checkbox-input {
        display: inline-block;
    }

    .jsa-report {

        .latitude,
        .longitude {
            @include coordsStyle();
        }
    }
}

@media screen and (width: 568px) {
    @include jsaSafetySteps();
    @include detailsPageStepRowsSmallDevices();

    .latitude,
    .longitude {
        @include coordsStyle();
    }
}

@media screen and (max-width: 320px) {
    @include detailsPageStepRowsSmallDevices();
}

.nonUsersRow{
    margin-top: 10px;
}
.user-data {
    color: white;
}
#print{
   .user-data {
       color: black;
   }
}
.acknowledged{
    background-color: rgb(38, 166, 91);
    margin: auto;
    width: 25px;
    height: 25px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 100%;
    color: white;
}
.unacknowledged{
    background-color: rgb(166, 38, 49);
    margin: auto;
    width: 25px;
    height: 25px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 100%;
    color: white;
}  
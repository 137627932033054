@import '../OIForm/oiForm.scss';
@import '../Group/addGroupForm.scss';
.project-form,
.project-details,
.update-project {
    padding: 20px;
    color:white;
    background-color:#003152;
    .show-grid {
        padding-bottom: 20px;
    }
    .project-form-buttons {
        text-align: center;
    }
    @include editButtonStyle();
}

.project-reports {
    .show-grid {
        padding-bottom: 20px;
    }
    .project-form-buttons {
        text-align: center;
    }
    min-height: 84vh;
}

@media screen and (min-width:667px) and (max-width: 767px) {
    .project-form,
    .update-project {
        .show-grid {
            padding-bottom: 5px;            
        }
    }
}

@media screen and (max-width: 768px) {
    .project-form,
    .update-project {
        .show-grid {
            padding-bottom: 5px;
        }
    }
}
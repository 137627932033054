.permit-to-work-form { 
    .form-header {
        text-align: center;
        .forward-button {
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            right: 11%;
            top: 0%;
        }
        .step-label {
            display: inline-block;
            line-height: 25px;
            color: #BFBFBF;
            padding: 0px 60px;
            font-size: 17px;
        }
        .backward-button {
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            left: 9%;
            top: 0%;
        }
        .navigateNextButton{
            background-color: green;
            padding: 4px;
            border-radius: 10px;
            cursor: pointer;
            color: white;
        }
        .navigatePreviousButton{
            background-color: green;
            padding: 4px;
            border-radius: 10px;
            cursor: pointer;
            color: white;
        }
        .navigateNextButton.nextBtnOnly {
            margin-right: 10px;
            background-color: gray;
            padding: 4px 11px; 
        }
        .navigatePreviousButton.previousBtnOnly{
            margin-left: 10px;
            background-color: gray;
            padding: 4px 11px; 
        }
    }
    .isolation-details{
        .table-responsive-lg { overflow-y: auto;}
        table { width: 250%;
            th, td { color: yellow; }
        }
        #isolationDetailIsolationType, #isolationDetailIsolationMethod{
            /* position: absolute; */
            width: 140px;
        }
        
    }
    .verify-JSA-workToPermit{
        margin-top: 10%;
        button{
            top: 70%; 
            transform: translate(-50%, -50%);
            color: white;
            margin: 0px 20px;
            border: none;
            background-color: #26a65b;
            font-size: 18px;
            height: 40px;
            border-radius: 17px;
            outline: none;
            border-radius: 10px;
        }
    }
    .trash{
        color: red;
        cursor: pointer;
    }
    .ATCSEHints{
        color: #fff !important;font-size: 14px;
    }
    .attribute-menu-dropdown{
        display: inline;
        button{
            padding: 0px 5px;
        }
    }
    .sequence-number-position{
        left: 85px !important;
    }
    .isolation-details .react-date-picker{
        width: 160px !important;
    }
    
    .assessment-hint{ 
        .card-header{
            background-color: #dcdcdc;
            button {
                color: rgb(84, 121, 175);
                border: 1px solid #5479AF;
                background-color: white;
                float: right;
            }
        }
        .card-body{
            background-color: #003152 !important;
        }
    }
    .form-section-title {
        font-size: 18px;
        text-align: center;
        padding-top: 0px !important;
        font-weight: 600;
        padding: 1px 120px;
        background-color: gray;
        border-radius: 5px;
        margin-bottom: 10px;
        label{ 
            color: white !important;
        }
    }
    .signOffAddBtn{
        border: 5px green solid;
        color: green;
    }
}

.checklist-attribute-modal-table-buttons, .permit-to-work-form .table-buttons {
    text-align: center;
    margin-top: 15px;
    width: 100%;
    /* border-bottom: 1px solid #4b77be; */
    padding-bottom: 20px; 
    .add-row {
        width: 66%;
        background-color: #4b77be;
        border: none;
        border-radius: 17px;
        color: white;
        height: 34px;
    }
}
.ptwDetails{
    .ATCSEHints{
        color: #fff !important;font-size: 14px;
    }
    .signature-img { 
        background-color: white;
        padding: 2px;
        border-radius: 4px;
        border: solid 2px gray;/* border: '1px solid #d2d2d2'; background-color: 'white';  */}
    .form-page-title{
        color: white;
        margin-top: 15px;
        background-color: gray;
        padding: 10px;
        border-radius: 10px; 
        text-align: center;
        font-size: 20px;
    }
    .form-section-title {
        font-size: 18px;
        text-align: center;
        color: yellow;
        font-weight: 600;
        span{ 
            padding: 1px 120px;
            background-color: gray;
            border-radius: 5px;
        }
    }
    .checklist-form{
        .col-12 {
            margin-top: unset !important;
        }
        .text-align{ text-align: center !important;}
    } 
    .ansYes {
        font-size: 20px; 
        font-weight: 600;
    }
    .ansNo{
        color: #918c8c; 
    }
}
.permit-to-work-form, .ptwDetails {
    fieldset.Hot-workpermitForm{
        border: 12px solid #ff0000;
    }
    fieldset.Confined-SpacepermitForm{
        border: 12px solid #9aa5c5;
    }
    fieldset.Critical-WorkspermitForm{
        border: 12px solid orange;
    }
    fieldset.GeneralpermitForm{
        border: 12px solid #5ec77e;
    }
}
.ptwDetails fieldset{
    border-radius: 40px; padding: 0px 20px !important;
}
.checklist-border{
    margin-bottom: 20px;
    border: 2px solid #fff !important;
}
.ptwDetails .checklist-border{
    margin-top: 20px;
}
@import '../../constants.scss';
@import '../OIForm/oiForm.scss';
@mixin submittButtonGroup {
    margin: 0 auto;
    width: 100%;
    text-align: center;
}

@mixin editButtonStyle {
    .edit {
        text-align: center;
        width: 25%;
        height: 39px;
        border: none;
        background: #26a65b;
        border-radius: 20px;
        color: white;
    }
}

@include uploadImage();
.add-group,
.update-group,
.group-reports,
.group-details,
.personal-safety-form-details {
    padding: 20px;
    color:white;
    background-color: #003152;
    .show-grid {
        padding-bottom: 20px;
        .text {
            text-align: right;
            color: #828282;
            padding: 5px;
        }
    }
    .submit {
        display: block;
        margin: auto;
        width: 150px;
        font-size: 20px;
        position: relative;
        top: 10px;
    }
    @include editButtonStyle();
}

.group-reports {
    min-height: 84vh;
    padding: 20px;
    @include editButtonStyle();
}

@media screen and (max-width: 767px) {
    .add-group,
    .update-group {
        .Select {
            left: 0px !important;
        }
        width: 85%;
        .show-grid {
            padding-bottom: 5px;
        }
        .text {
            left: 62px;
            position: relative;
            text-align: left;
            margin-bottom: 5px;
        }
    }
    .Select {
        left: 60px;
    }
    .submit {
        display: inline-block;
        margin-right: 32px;
        width: 113px;
        font-size: 17px;
        position: relative;
        top: 10px;
        height: 42px;
        left: 47px;
        background-color: #E8AC1C;
        border-radius: 20px;
        color: white;
    }
}
.activity-charts {
    .close-button {
        position: absolute;
        right: 0;
        top: 0;
        width: 35px;
        text-align: center;
        font-size: 30px;
        color: #8e8e8e;
        line-height: 34px;
        &:hover {
            cursor: pointer;
        }
    }
    .chart {
        .legends {
            height: 128px;
        }
    }
}
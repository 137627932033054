    @import '../OIForm/oiForm.scss';
    @import '../Group/addGroupForm.scss';
    @mixin listPageButtons($leftDistance: -30px) {
        width: 60px;
        height: 60px;
        border-radius: 94px;
        font-size: 36px;
        border: none;
        color: white;
        position: fixed;
        box-shadow: 3px 4px 6px #26a65b;
        right: calc((100% - #{$basepageWidth})/2 - #{$leftDistance});
    }
    @mixin addPageButtons() {
        background-color: $submitButtonColor;
        @include listPageButtons();
        right: calc(100% - 85vw);
        top: 89%;
    }
    .checklistHome .add-new-report{
        @include addPageButtons();
    }
    $summaryButton: #dadada;
    .list-page {
        min-height: 84vh;
        padding: 20px;
        .page-title {
            padding-bottom: 10px;
            .form-title {
                text-align: center;
            }
        }
        @include editButtonStyle();
        .reports-table {
            .heading {
                .col-sm-1 {
                    padding: 0px 0px 0px 15px;
                }
            }
            .report-row {}
        }
         
        .fa-sort, .fa-caret-up, .fa-caret-down{
            color: #fff;
            cursor: pointer;
        } 
    }

    /* .oi-list-wrapper,
    .incident-list-wrapper {
        .list-page {
            .form-title {
                text-align: left;
            }
        }
    } */

    .search {
        .search-button {
            position: relative;
            /* padding: 20px 0px; */
            padding-top: 20px;
            top: 0;
            left: 28px;
            color: white;
            width: 90%;
            cursor: pointer;
        }
        .search-button-hidden {
            visibility: hidden;
            display: none;
            position: absolute;
        }
        .search-box-hidden {
            visibility: hidden;
            position: absolute;
            -webkit-transition: width 1s ease-out;
            /* For Safari 3.1 to 6.0 */
            transition: width 1s ease-out;
            width: 0px;
        }
        .search-box {
            width: 100%;
            /* left: 4%; */
            padding: 10px 0px;
            visibility: visible;
            -webkit-transition: width 1s ease-out;
            /* For Safari 3.1 to 6.0 */
            transition: width 1s ease-out;
            position: relative;
            .fa-search {
                position: absolute;
                top: 31%;
                font-size: 20px;
                left: 2%;
                color: #bbbbbb;
                /* s */
            }
            .fa-spin{
                color: red;
                font-size: 30px;
                /* font-weight: 600; */
                top: 15px;
                position: absolute;
                left: 10px;
            }
            .form-control {
                padding: 6px 12px 6px 52px;
            }
            .fa-times {
                position: absolute;
                top: 31%;
                font-size: 20px;
                right: 2%;
                color: #bbbbbb;
                cursor: pointer;
            }
        }
    }
    .min-wide-list-page{
        width: 65vw !important;
    }
    .wide-list-page{
        width: 75vw !important;}
    .extra-wide-list-page{
        width: 85vw !important;
    }
    .very-extra-wide-list-page{
        width: 95vw !important;
    }

    .min-wide-list-page, .wide-list-page, .extra-wide-list-page {
        .report-row {
            .col-sm-1 {
                .dropdown {
                    left: 0;
                }
                .serial-no {
                    display: inline-block;
                    position: absolute;
                    line-height: 21px;
                    left: 7%;
                }
            }
        }
        .oi-list-wrapper,
        .incident-list-wrapper,
        .investigative-list-wrapper,
        .jsa-list-wrapper, 
        .reference {
            .add-new-report {
                @include addPageButtons(); 
            }
            .reports-table {
                padding: 20px 0px 8px 8px;
                /* padding: 0px 8px; */
                color:white;
            }
            .summary-page {
                background-color: $summaryButton;
                @include listPageButtons();
                right: calc(100% - 85vw);
                top: 81.5%;
                z-index: 1;
                .fa {
                    font-size: 21px;
                    position: relative;
                    bottom: 5px;
                    color: black;
                }
            }
        }
    }

    .user-list-wrapper,
    .group-list-wrapper,
    .project-list-wrapper,
    .office-safety-wrapper,
    .reference {
        background-color: #003152;
        .office-safety-wrapper,
        .reports-table { 
            padding: 20px 0px 8px 8px;
            /* padding: 0px 8px; */
            color:white;
        }
        .add-new-report {
            background-color: $submitButtonColor;
            @include listPageButtons();
            top: 89%;
            z-index: 1;
        }
        .summary-page {
            background-color: #dadada;
            @include listPageButtons();
            top: 81%;
            z-index: 1;
            .fa {
                font-size: 21px;
                position: relative;
                bottom: 5px;
                color: black;
            }
        }
    }

    .report-row {
        margin: unset;
        text-align: center;
        &:hover {
            background-color: #a7bddb;
            cursor: pointer;
            .col-sm-1 {
                .dropdown {
                    .btn {
                        background-color: #eaeaea;
                    }
                }
            }
            &:hover {
                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16), 0 6px 6px rgba(0, 0, 0, 0.10);
                transition: all 0.1s cubic-bezier(.25, .8, .25, 1);
                border-radius: 5px;
            }
        }
        .clickable-list-item {
            padding: 20px 0px;
        }
        .col-sm-1 {
            padding: 20px 0px;
            .dropdown {
                left: 9px; display: inline;
                .btn {
                    border: none;
                    box-shadow: none;
                    display: inline-block;
                    margin-left: -32px;
                    padding: 0px 5px;
                    &:hover {
                        background-color: white !important;
                    }
                }
                .caret {
                    display: none
                }
                .dropdown-menu {
                    left: -25px;
                    .item {
                        a {
                            &:hover {
                                background-color: $reactSelectHoverColor;
                            }
                        }
                    }
                }
            }
            .dropdown-button {
                display: inline-block;
                padding-right: 10px;
                margin: 0px 0px 0px -30px;
                &:hover {
                    background: white;
                    margin-right: 10px;
                    padding-right: 5px;
                    padding-left: 5px;
                }
            }
            .menu-items {
                width: 70px;
                position: absolute;
                left: -20px;
                top: 35px;
                border: 0.2px solid #e0e0e0;
                z-index: 1;
                border-radius: 5px;
                background: white;
                .item {
                    text-align: left;
                    display: block;
                    padding: 5px;
                    &:hover {
                        background: #e1efff;
                    }
                }
            }
            .serial-no {
                display: inline-block;
                position: absolute;
                line-height: 25px;
                left: 70px;
            }
        }
    }
    .followup-list-modal .add-new-report {
        background-color: $submitButtonColor;
        @include listPageButtons();
        /* top: 89%; */
        top: 84% !important;
        left: 69% !important;
        z-index: 1;
    }
    @mixin listPageButton($top2, $top1: 89%) {
        .wide-list-page {
            .oi-list-wrapper,
            .incident-list-wrapper,
            .investigative-list-wrapper,
            .jsa-list-wrapper,
            .reference {
                .add-new-report {
                    top: calc(100% - #{$top1});
                }
                .summary-page {
                    top: calc(100% - #{$top2});
                }
            }
        }
    }

    @media only screen and (max-width: 480px) {
        @include listPageButton(23vh, 11vh);
    }

    @media screen and (orientation: landscape) {
        @include listPageButton(19vh, 37vh);
    }

    @media (min-width: 1600px) {
        @include listPageButton(17vh, 9vh);
    }

    @media (min-width: 768px) and (max-width: 1599px) {
        @include listPageButton(12vh, 23vh);
    }

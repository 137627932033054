@import '../../../constants.scss';
@import '../../../generals.scss';
@mixin navbarTransition {
    -webkit-transition: all 0.75s ease;
    -moz-transition: all 0.75s ease;
    -o-transition: all 0.75s ease;
    -ms-transition: all 0.75s ease;
    transition: all 0.75s ease;
}

@mixin notificationOnPhone {
    .notification-badge {
        position: absolute;
        width: 18px;
        color: black;
        background-color: white;
        border-radius: 17px;
        font-size: 11px;
        right: auto;
        margin-left: 5px;
    }
    .notification-badge.user {
        top: 10px;
        display: block;
        left: 60%;
    }
}

.navbar {
    li {
        vertical-align: middle;
    }
    a:hover,
    a:focus,
    a:active {
        color: rgb(153, 153, 153);
    }
    a {
        transition: color 0.1s, background-color 0.1s;
    }
    a {
        position: relative;
        display: block;
        padding: 16px 0;
        line-height: 16px;
        transition: color 0.1s, background-color 0.1s, padding 0.2s ease-in;
        color: #fff;
    }
    a::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 3px;
        left: 0;
        height: 3px;
        width: 100%;
        margin-bottom: -5px;
        background-color: #fff;
        transform-origin: right top;
        transform: scale(0, 1);
        transition: color 0.1s, transform 0.2s ease-out;
    }
    a:active::before {
        background-color: #fff;
    }
    a:hover::before,
    a:focus::before {
        transform-origin: left top;
        transform: scale(1, 1);
    }
}

@mixin tabletStyle() {
    .navbar-nav {
        background-color: black !important;
        float: none !important;
        text-align: center;
        li {
            a {
                color: #9d9d9d !important;
                background-color: unset !important;
                padding: 15px;
            }
            .dropdown-menu {
                text-align: center;
            }
        }
        li.active {
            border-bottom: none;
            color: white !important;
            a {
                color: white !important;
                background-color: unset !important;
            }
        }
    }
}

@mixin navbarLogo {
    @include notificationOnPhone();
    .navbar {
        .container {
            padding: unset;
            .navbar-header {
                width: $hundredPercent;
                padding: unset;
                margin: unset;
                .navbar-brand {
                    padding: 0;
                    margin: 10px 0;
                }
                .navbar-toggle {
                    margin-top: 16px;
                }
            }
        }
    }
}
.navbar {
    /* .container{ width: 1200px !important; } */
    border-color: transparent !important;
    border-radius: 0px !important;
    height: $hundredPercent;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #003152 !important;
    background-attachment: fixed;
    .navbar-header {
        &:hover {
            cursor: pointer;
        }
        width: auto;
        .navbar-brand {
            padding: 0;
            margin: 10px 0;
        }
    }
    @mixin navbarTransition {
        -webkit-transition: 0.50s ease;
        -moz-transition: 0.50s ease;
        -o-transition: 0.50s ease;
        -ms-transition: 0.50s ease;
        transition: 0.50s ease;
    }
    .nav,
    .navbar-nav {
        color: black !important;
        float: right !important;
        font-size: 18px;
        .nav-item{
            padding-left: 7px;
        }
        .nav-dropdown,
        .open,
        .dropdown {
            .dropdown-toggle {
                background-color: transparent !important;
            }
        }
        .error,
        .loading {
            display: inline-block;
            padding-top: 23px;
        } 
        .dropdown-menu {
            .sub-menu-dropdown {
                .dropdown-menu {
                    display: block;
                    visibility: hidden;
                    position: relative;
                    padding: 0;
                    margin: 0;
                    width: 0px;
                    max-height: 0;
                    opacity: 0;
                    @include navbarTransition();
                }
            }
            .sub-menu {
                .dropdown-menu {
                    @include navbarTransition();
                    position: relative;
                    visibility: visible;
                    /* max-height: 121px; */
                    width: auto;
                }
            }
            background-color: black;
            div {
                a {
                    padding: 5px !important;
                    outline: none;
                    color: white !important;
                    &:focus,
                    &:hover {
                        color: white !important;
                        background-color: black;
                    }
                }
            }
            div.active {
                border-bottom: none !important;
                color: #978A8A !important;
                a {
                    background-color: black !important;
                }
            }
            &:focus,
            &:hover {
                color: white !important;
            }
            a {
                padding: 10px;
                outline: none;
                color: white !important;
            }
        }
        .directLink {
            padding: 25px;
            outline: none;
            color: white !important;
        }
        a {
            padding: 25px;
            outline: none;
            color: white !important;
        }
        div.active, a.active, .div:active, a:active {
            margin: 0px 0px -2px 0px; 
            border-bottom: 2px solid $menuActiveBorderBottom;
            background-color: $menuActive !important;
            a {
                 
                //background-color: $menuActive !important;
            }
            #admin-dropdown{
                background-color: $menuActive !important;
            }
        } 
    }
}

@media screen and (max-width: 767px) {
    .nav-dropdown {
        top: 0px;
    }
    .navbar {
        .container {
            padding: unset;
            .navbar-header {
                width: $hundredPercent;
                padding: unset;
                margin: unset;
            }
        }
        .nav {
            a {
                background-color: black !important;
            }
        }
        .navbar {
            .navbar-nav {
                font-size: 14px;
            }
        }
        @include tabletStyle();
        .navbar-form,
        .navbar-collapse {
            background-color: black;
        }
        .navbar-toggle.collapsed {
            .icon-bar {
                background-color: #828282 !important;
            }
        }
        .navbar-toggle {
            margin: 12px 0px 0px 0px;
            padding: 15px 20px;
            border-color: transparent !important;
            .icon-bar {
                background-color: #828282 !important;
            }
        }
        .navbar-toggle {
            &:focus,
            &:hover {
                background-color: transparent !important;
                .icon-bar {
                    background-color: #000000;
                }
            }
        }
    }
}

@media (width: 768px) {}

@media (min-width:768px) and (max-width: 994px) {
    .navbar {
        .navbar-nav {
            li {
                a {
                }
            }
        }
    }
}

@media screen and (width: 568px) {
    @include navbarLogo();
}

@media screen and (width: 667px) {
    @include navbarLogo();
}

@media screen and (width: 736px) {
    @include navbarLogo();
}

@media (min-width: 1023px) and (max-width:1208px) {
    .navbar {
        .navbar-nav {
            font-size: 14px;
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .navbar {
        .container {
            width: 100%;
            padding: 0;
        }
        .navbar-header {
            float: none;
            padding: 0 30px
        }
        .navbar-toggle {
            background-color: transparent !important;
            border: none !important;
            height: 54px;
            display: block;
        }
        .navbar-collapse {
            border-top: 1px solid transparent;
            box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
            display: none !important;
            transition: height 0.25s ease-in;
        }
        .navbar-collapse.collapse.in {
            display: block !important;
            transition: height 0.25s ease-in;
            background-color: black;
            text-align: center;
            padding: 0;
            .dropdown-menu {
                position: relative;
                top: 0;
                float: none;
                text-align: center;
            }
        }
        .collapse {
            transition: height 0.25s ease-in;
        }
        .navbar-nav {
            float: none !important;
            margin: 7.5px -15px;
        }
        .navbar-nav>li {
            float: none;
        }
        .navbar-nav>li>a {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .navbar {
        .container{ width: 1200px !important; }
    }
}

.company-logo{ margin-left:20px !important; max-width: 120px !important;}
.app-logo{ max-width: 150px; height: 4.5vw; }

.label-new {  
    top: 10%;
    width: 35px;
    background-color: #f35d5d;
    color: white; 
    font-size: 12px;
    height: 18px;
    position: absolute; 
    border-radius: 21%;
    right: 5%;
    margin-left: 5px;
    text-align: center;
    text-transform: capitalize;
}
 
.reports-dropdown .open.sub-menu{
    .dropdown-toggle{
        border-bottom: solid 3px white !important;
    }
    ul li a{
        /* float: right !important; */
        text-indent: 25px !important; 
    } 
    .second-sub-menu{ 
        ul li a { 
            text-indent: 45px !important; 
        }  
    }
}
.reports-dropdown .menu-b-bottom{
    border-bottom: solid 3px white !important;
}
.navbar .nav li .dropdown-menu li.menu-b-bottom.active{
    border-bottom: solid 3px white !important;
}
ul.dropdown-menu { overflow: auto; max-height: 88vh; }
.dropdown-item:focus, .dropdown-item:hover { 
    background-color: #0d4781;
    color: #fff;
}
@media screen and (max-width:768px) {
    .legends {
        text-align: left;
        .legend {
            margin: 0px 10px;
            .legend-text {
                position: relative;
                bottom: 11px;
                left: 8px;
                user-select: none;
            }
        }
    }
}
@import '../../../constants.scss';

.psp-form {
    width: calc(100% - 150px);
}

.psp-reports {
    .show-grid {
        padding-bottom: 10px !important;
    }
}

.personal-safety-form {
    padding: 20px 30px;

    .show-grid {
        padding-bottom: 20px;
        color: #BFBFBF;

        .input {
            text-align: center;
            color: #BFBFBF;
        }
    }

    .persona-form-table {
        border-bottom: 1px solid black;
    }

    .action-label {
        color: yellow;
        padding-bottom: 5px;
    }

    .answer-label {
        text-align: right;
    }

    textarea {
        overflow-x: hidden;
        resize: none;
    }

    Label {
        color: yellow;
    }
}

.action-label {
    color: yellow;
    padding-bottom: 5px;
}

@media screen and (max-width: 768px) {
    .personal-safety-form {
        .answer-label {
            text-align: left;
        }
    }
}